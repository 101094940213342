export const LANG_FR_NAME = 'fr';

export const LANG_FR_TRANS = {
    // supported lang
    'English': 'Anglais',
    'French': 'Français',
    'Please choose an option': '--Choisir une option--',
    'delete': 'Supprimer',
    'view': 'Visualiser',
    'approve': 'Approuver',
    'reject': 'Refuser',
    // calendar
    'Calendar.today': 'Aujourd\'hui',
    'Calendar.next': 'Suivant',
    'Calendar.previous': 'Précédent',
    'Calendar.week': 'Semaine',
    'Calendar.in': 'en',
    'Calendar.month': 'Mois',
    'Calendar.day': 'Jour',
    'mrs': 'Mme',
    'miss': 'Melle',
    'mr': 'Mr',
    // Home component
    'yes': 'Oui',
    'no': 'Non',
    'an interface for data': 'une interface pour les données',
    'Home': 'Accueil',
    'Register': 'Enregistrement',
    'goback': 'Retour',
    'save': 'Enregistrer',
    'detail': 'Détail',
    'Connexion': 'Connection',
    'Help': 'Assistance',
    'Settings': 'Règlages',
    // app component
    'hello world': 'Bonjour tout le monde',
    'hello greet': 'Bonjour, %0 %1!',
    'well done': '%0 bienvenu',
    'good bye': 'A bientôt', // only define in English
    'Double click to edit': 'Double clicquer pour éditer',
    'responsesCount': 'Nombre de réponses',
    'rowsCount': 'Nombre de lignes',
    // login cmponent
    'Sign in': 'Connexion',
    'Sign out': 'Quitter',    
    'Dark Theme': 'Thème sombre',
    'Ligth Theme': 'Thème clair',
    'ID': 'Identifiant',
    'ID field': 'Saisir l\'indentifiant',
    'Password': 'Mot de passe',
    'Login': 'Accéder',
    'language': 'Langue',
    'lang': 'Langue',
    'Country': 'Pays',
    'Choose country': 'Choisir un pays',
    'Nope, try again.': 'Echec identification, Ré-essayer.',
    'Try again with another login': 'Essayer avec un autre identifiant',
    'Login is required': 'Un identifiant est requis',
    'Your login should be at 3 characters': 'Votre identifiant doit avoir au moins 3 caractères',
    'Confirm password': 'Confirmer le mot de passe',
    'Your password does not match': 'Les mots de passe ne sont pas identiques',
    'edit user': 'editer l\'utilisateur %0',
    'delete user': 'supprimer l\'utilisateur %0',
    'change password': 'demande de modification du mot de passe. Utilisateur %0',
    'confirm change password': 'Le mot de passe de l\'utilisateur %0 a été changé. Un mail a été adressé à : %1',
    'Birth day': 'Date de naissance',
    'Birth day is Required': 'Une date de naissance est requise',
    'This is not a valid year': 'L\'année de naissance indiquée n\'est pas valide (min 1920)',
    'Creation': 'Création',
    'Update': 'Mise à jour',
    'submit': 'Valider',
    'cancel': 'Annuler',
    'dataAccess': 'Habilitations',
    'edit tooltip': 'Editer l\'enregistrement',
    'add tooltip': 'Ajouter un enregistrement',
    'quotation tooltip': 'devis',
    'statut tooltip': 'Changement statut',
    'try tooltip': 'Tester l\'enregistrement',
    'file-text-o tooltip': 'nouveau dossier',
    'dataAccess tooltip': 'Habilitations accès',
    'labelData tooltip': 'Labellisation des données',
    'labelData': 'Labellisation des données',
    'data display tooltip': 'Choix données affichées',
    'dataDisplay': 'Choix données affichées',
    'data attributes tooltip': 'Attributs des colonnes',
    'dataAttributes': 'Attributs des colonnes',

    'trash tooltip': 'Supprimer l\'enregistrement',
    'file tooltip': 'Télécharger le fichier',
    'test connection OK': '%0 : Connexion réussie !',
    'test connection KO': '%0 : Echec de la connexion !',
    'Test connect': 'Test connexion',
    'test request OK': '%0 : Exécution requête réussie !',
    'test request KO': '%0 : Echec exécution requête !',
    'Test request': 'Test requête',
    'testappli': 'Test application',
    'reloadConfig': 'Recharger configuration',
    'reloadWebdav': 'Recharger webdav',
    'ReloadWebdavInprogress': 'Rechargement webdav en cours ... ',
    'ReloadWebdavDone': 'Rechargement webdav terminé. ',
    'ReloadWebdavError': 'Rechargement webdav en erreur !! ',
    'testJms': 'Test Jms',
    'test submitted': 'Test envoyé',
    'userNameFound': 'Cet identifiant est déjà utilisé',
    'Input error': 'Les informations saisies ne sont pas correctes',
    'Invalid birthday': 'Année de naissance invalide',
    'Confirming password is required': 'La confirmation du mot de passe est requise',
    'tooltipBirthDay': 'La date doit être saisie au format : %0',
    'FirstName': 'Prénom',
    'FirstName field': 'Entrer le prénom',
    'Firstname is required': 'Le prénom est obligatoire',
    'Your Firstname should be at 3 characters': 'Le prénom doit avoir au moins 3 caractères',
    'LastName': 'Nom',
    'LastName field': 'Entrer le nom',
    'Lastname is required': 'Le nom est obligatoire',
    'Your Lastname should be at 3 characters': 'Le nom doit avoir au moins 3 caractères',
    'Password field': 'Entrer le mot de passe',
    'Password is required': 'Le mot de passe est requis',
    'Your Password should be more than 3  characters': 'Votre mot de passse doit avoir plus 3 caractères',
    'Sexe': 'Genre',
    'Choose one gender': 'Choisir un genre',
    'M': 'H',
    'F': 'F',
    'T': 'T',
    'Sexe is required': 'Le choix d\'un sexe est requis',
    'Email': 'Adresse messagerie',
    'Email field': 'Entrer une adresse de messagerie valide',
    'Email is required': 'Adresse messagerie requise',
    'This is not a valid email': 'Adresse messagerie invalide',
    'Email is already used': 'Adresse mail déjà utilisée',
    'USER': 'Utilisateur',
    'ADMIN': 'Administrateur',
    'MANAGER': 'Manager',
    'REGISTER': 'Profils',
    'EXPERT': 'Expert',
    'ENTITY': 'Responsable entité',
    'DESIGNER': 'Designer',
    'SUPERVISOR': 'Superviseur',
    'DATA-MANAGER': 'Manager Data',
// Menu
    'UsersAccount': 'Comptes utilisateurs',
    'Profiles': 'Profils',
    'Entities': 'Entités',
    'EntitiesMenu': 'Menus Entités',
    'EntityMenu': 'Menu : %0',
    'EntityMenuAddRoot': 'Ajouter un premier niveau',
    'EntityMenuAddNode': 'Ajouter un item dans %0',
    'EntityMenuRemoveNode': 'Supprimer item %0',
    'EntityMenuRenameNode': 'Renommer item %0',
    'UsersGroups': 'Groupes utilisateurs',
    'UpdatePwd': 'Modification mot de passe',
    'data-management': 'Outils de gestion de données',
    'designer': 'Rapports',
    'datasources': 'Sources de données',
    'SQL requests': 'Requêtes SQL',
    'access data (RGPD)': 'Accès aux données',
    'label data': 'Labellisation des données',
    'reports design': 'Définition des rapports',
    'reports validation': 'Mise en production',
    'working validation': 'Validation des rapports',
    'reports validated': 'Rapports validés',
    'changeEntity': 'Changer d\'entité',
// Fonctionnalités
    'global filter': 'Filtre général ....',
    'clear filter': 'Supprimer les filtres',
    'filter by column': 'Filtre par colonne',
    'filter editor': 'Editeur de filtres',
// chargement fichiers
    'uploadFiles': 'Téléversement',
    'verifyFiles': 'Contrôle fichier',
    'displayImages': 'Affichage images',
    'displayList': 'Liste fichiers',
    'displayPdf': 'Affichage pdf',
    'Select': 'Sélection fichiers',
    'Drop zone': 'Zone de dépôt des fichiers',
    'nameFile': 'Nom fichier',
    'sizeFile': 'Taille fichier',
    'ProgressFile': 'Chargement',
    'uploadedFile': 'Statut',
    'legendFile': 'Commentaire fichier',
    'typeFile': 'Type fichier',
    'ActionsFile': 'Actions',
    'UploadQueue': 'File d\'attente',
    'QueueLength': 'Nombre de fichiers',
    'QueueProgress': 'Progression',
    'UploadFile': 'Téléchargement fichier',
    'CancelFile': 'Annulation fichier',
    'RemoveFile': 'Effacement fichier',
    'UploadAll': 'Téléchargement liste',
    'CancelAll': 'Annulation liste',
    'RemoveAll': 'Effacement de la liste',
    'Drop your file here!': 'Déposer les fichiers ici!',
    'SelectFiles': 'Sélection fichiers',
    'SelectFile': 'Sélection fichier',
    'waiting': 'En attente',
    'uploaded': 'Téléchargé',
    'Duplicate file': 'Fichier dupliqué',
    'Upload error file': 'Erreur de téléchargement',
    'Upload lines error': 'Erreurs  sur lignes ',
    // modal
    'I never close': 'Jamais fermé',
    'I will always close': 'Toujours fermé',
    // end modal
    'mat-paginator-intl.first_page': 'première page',
    'mat-paginator-intl.items_per_page': 'items par page',
    'mat-paginator-intl.last_page': 'dernière page',
    'mat-paginator-intl.next_page': 'page suivante',
    'mat-paginator-intl.previous_page': 'page précédente',
    'mat-paginator-intl.of': 'sur',
    'Delete / Edit': 'Effacer / Modifier',
    'empty': 'est vide',
    'contain': 'contient',
    'start': 'débute par',
    'end': 'finit par',
    'less than': 'plus petit que',
    'greater than': 'plus grand que',
    'equal': 'est égal à',
    'columns selection': 'Sélection des colonnes',
    'Close': 'Fermer',
    'Delete': 'Supprimer',
    'Apply': 'Appliquer',
    'Confirmation': 'Confirmation',
    'Choose Columns': 'Selectionner les colonnes',
    'updatePwdModal': 'Modification du mot de passe',
    'modifPwd': 'Modifier le mode de passe',
    // entities card component errors messages
    'Entity is required': 'Entité requise',
    'Your entity should be at 2 characters': 'L\'entité doit comporterplus de 2 caractères',
    'entityFound': 'L\'entité existe déjà',
    'Entity label is required': 'Un libellé d\entité est requis',
    'Your entity label should be at 3 characters': 'Le libellé de l\'entité doit comporter plus de 3 caractères',
    'Supervisor email is required': 'Un email de superviseur est requis',
    'Group is required': 'Un groupe est requis',
    'authorizedFunctions': 'Fonctionnalités autorisées',
    'authorizedTags': 'Tags autorisés',
    'prohibitedTags': 'Tags non autorisés',
    // messages bootsrap

    'TEST MESSAGE INFO': 'CECI EST UN TEST MESSAGE INFO => %0  %1',
    'The maximum number of files is reached': 'Le nombre maximal de fichiers est atteint : %0 pour %1',
    'extension error': 'Extension non reconnue',
    'unrecognized extension': 'Fichier :  %1 - Extension %0 non reconnue',
    'This file already exist': 'Le fichier %0 est déjà présent dans la liste',
    'ReportAnalyzeCSV': 'Rapport analyse CSV',
    'missingColumn': 'Colonne %0 absente',
    'unknownColumn': 'Colonne %0 inconnue',
    'AnalyzeFile': 'Analyse du fichier %0',
    'headersName': 'Nom de colonnes OK',
    'headersNameError': 'Fichier : %0 => Erreur dans les noms de colonnes => %1 ',
    'allColumnsPresents': 'Fichier : %0 => Le nom des colonnes présentes est OK ',
    'noLinesDataPresents': 'Fichier : %0 => Absence de ligne de données',
    'dataLinesAnalyzed': 'Fichier : %0 => Lignes de données validées',
    'too many files': 'Nombre de fichiers trop élevé',
    'too heavy file': 'Taille de fichier non conforme',
    'too heavy file size': 'Fichier : %0 => trop important => %1',
    'dataLinesAnalyzedKO': 'Fichier : %0 => Analyse des lignes en erreur => %1',
    'columnActionMissing': 'Fichier : %0 => Colonne action absente',
    'actionLabelKO': 'Fichier : %0 (col %1) (lgn %2) Valeur ( %3 ) action non acceptée - attendu : %4',
    'dataKOMandatory': 'Fichier : %0 (col %1) (lgn %2) Valeur obligatoire pour action ( %3 )',
    'dataKOForbidden': 'Fichier : %0 (col %1) (lgn %2) Valeur interdite pour action ( %3 )',
    'decimalFormatKO': 'Fichier : %0 (col %1) (lgn %2) Valeur non conforme ( %3 )',
    'dataKOAcceptedValues': 'Fichier : %0 (col %1) (lgn %2) Valeur ( %3 ) non acceptée pour action  ( %4 )- attendu : %5',
    'columnsMissing': 'Fichier : %0 Colonne(s) obligatoire(s) absente(s) %1 pour action  ( %2 )',
    'SupportedLangKO': 'Fichier : %0 (col %1) (lgn %2) Valeur ( %3 ) langue non supportée  - attendu : %4',
    'SupportedRoleKO': 'Fichier : %0 (col %1) (lgn %2) Valeur ( %3 ) role non supporté  - attendu : %4',
    'ControlRoleKO': 'Fichier : %0 (col %1) (lgn %2) Valeur ( %3 ) role hors habilitation',
    'EmailFormatKO': 'Fichier : %0 (col %1) (lgn %2) Valeur ( %3 ) format email non conforme',
    'userEntityNameKO': 'Fichier : %0 (col %1) (lgn %2) Valeur ( %3 ) entité non reconnue ( %4 )',
    'habilEntityNameKO': 'Fichier : %0 (col %1) (lgn %2) Valeur ( %3 ) entité non autorisée ( %4 )',
    'userGrpNameKO': 'Fichier : %0 (col %1) (lgn %2) Valeur ( %3 ) groupe non reconnu ( %4 )',
    'habilGrpNameKO': 'Fichier : %0 (col %1) (lgn %2) Valeur ( %3 ) groupe non autorisé ( %4 )',
    'userGrploadKO': 'Fichier : %0 (col %1) (lgn %2) Valeur ( %3 ) groupe non chargé pour entité ( %4 )',
    'verifyFileOK': 'Fichier : %0  => Vérification contenu OK',
    'verifyFileKO': 'Fichier : %0  => Vérification contenu KO',
    'StartUploadFile': 'Fichier : %0  => Début téléchargement',
    'UploadFileOK': 'Fichier : %0  => Téléchargement OK',
    'UploadFileKO': 'Fichier : %0 => Echec Upload : %1',
    'convertJsonDateKO': 'Fichier : %0 lgn (%1) conversion impossible de la date de naissance (%2)',
    'insertJsonDateKO': 'Fichier : %0 lgn (%1) insertion impossible JSON de la date de naissance (%2)',
    'convertJsonToModelKO': 'Fichier : %0 lgn (%1) conversion JSON vers Modele impossible',
    'accountExistsAddKO': 'Fichier : %0 lgn (%1) le compte utilisateur indiqué (%2) existe déjà',
    'accountNotExists': 'Fichier : %0 lgn (%1) opération %2 impossible compte (%3) inexistant',
    'operationNotExecuted': 'Fichier : %0 lgn (%1) opération %2 compte (%3) non exécutée',
    'FileNameProcess': 'Fichier traité',
    'MessageProcess': 'Message',
    'NbErrors': 'Erreur(s)',
    'BSN': 'Commerce',
    'HEALTH': 'Santé',
    'PERSO': 'Personnel',
    'PROD': 'Production',
    'TECH': 'Technique',
    'OTHER': 'Autre',
    'FIN': 'Finance',
    'COMMON': 'Communes',
    'IDENTITY': 'Identité',
    'RESTRAINT': 'Restreintes',
    'PRIVATE': 'Privées',
    'ALLData': 'Toutes',
    // data table des mjl_users
    'username': 'Identifiant utilisateur',
    'firstName': ' Prénom',
    'lastName': 'Nom',
    'sexe': 'Genre',
    'birthday': 'Date de naissance',
    'email': 'Adresse messagerie',
    'organization': 'Organisation',
    'profile': 'Profil',
    'dataFilter': 'Filtre de données',
    'roles': 'Roles',
    'Role is required': 'Au moins un rôle est requis',
    'Choice a role': 'Choisir au moins un rôle',
    'Role': 'Rôle (s)',
    'Choose lang': 'Choisir la langue utilisateur',
    'Validation error': 'Erreur de validation',
    'enabledAccount': 'Compte actif',
    'accountNonExpired': 'Compte non expiré',
    'accountNonLocked': 'Compte non bloqué',
    'credentialsNonExpired': 'Identifiants non expirés',
    'enabled': 'Actif',
    'userdebug': 'Sous debug',
    'userLang': 'Langue utilisateur',
    'extendedEntities': 'Accès entités',
    'extendedGrp': 'Accès groupes',
    'authorities': 'Habilitations',
    // scheduler
    'scheduler': 'Tâches programmées',
    'jobDetail': 'Détail',
    'jobJournal': 'Journal',
    'jobName': 'Nom',
    'jobGroup': 'Groupe',
    'jobStatus': 'Statut',
    'jobClass': 'Classe',
    'jobArgs': 'Arguments',
    'cronExpression': 'Expression cron',
    'jobDesc': 'Description',
    'deleteJob': 'Supprimer',
    'cancelJob': 'Annuler',
    'runJob': 'Test',
    'pauseJob': 'Pause',
    'resumeJob': 'Relancer',
    'dateJob': 'Date',
    'jobState': 'Etat',
    'STARTED': 'Démarré',
    'ENDED': 'Terminé',
    'lastRun': 'Dernier démarrage',
    'nextRun': 'Prochain démarrage',
    'choose class': 'Choisir une classe',
    'jobArgName': 'Nom argument',
    'jobArgValue': 'Valeur argument',
    'jobCreate': 'Création process',
    'remove job': 'Suppression process',
    'stop journal': 'Arrêter journal',
    'activate journal': 'Démarrer journal',
    // datasource card errors
    'Datasource label is required': 'Le libellé de la source de données est obligatoire',
    'Datasource engine is required': 'Le moteur de la source de données est obligatoire',
    'Datasource url is required': 'L\' url ou l\'ip de la source de données est obligatoire',
    'Datasource port is required': 'Le port de la source de données est obligatoire',
    'datasourceFound': 'Cette source données existe déjà',
    'Test connection error': 'Test de connexion en échec',
    'Test connection OK': 'Test de connexion validé',

    // request card  
    'Test request error': 'Test de requête en échec', 
    'Test request OK': 'Test de requête réussi', 
    'Connect Error': 'Erreur de connexion',
    'Statement Error': 'Erreur de connexion : pas de requête possible',
    'Execution Error': 'Erreur d\'exécution de la requête',
    'Request Error': 'Erreur d\'application de la requête',
    'Request not valid': 'Contenu de la requête invalide',
    'Forbiddens words': 'Contenu de la requête invalide',
    'dynamic restrictions': 'Filtres dynamiques',
    'Datasource is required': 'Source de données requise',
    'Documentary datasource is required': 'Source de données documentaire requise',
    'Mandatory filename field mapping': 'Mapping du champ \'nom de fichier\' obligatoire ',
    'Mandatory document name field mapping': 'Mapping du chamo \'Nom document\' obligatoire',
    'Path and filename fields can not be the same': 'Les champs chemin et nom de fichier ne peuvent être identiques',
    'Path and docname fields can not be the same': 'Les champs chemin et nom de document ne peuvent être identiques',
    'Filename and docname fields can not be the same': 'Les champs nom de fichier et nom de document ne peuvent être identiques',
    'ColumnName': 'Nom colonne',
    'Operande': 'Opérande',
    'RestrictValue': 'Critère',
    'Choose one column': 'Choisir une colonne',
    'Choose one operande': 'Choisir un opérande',
    'Choose one value': 'Choisir un critère',
    // report sheets
    'ReportCard': 'Fiche',
    'ReportOrganisation': 'Organisation',
    'ReportRowsPinned': 'Lignes fixes',
    'SubReport': 'Reports secondaires',
    'Dashboard': 'Tableau de bord',
    'ReportShowing': 'Présentation',
    'columnsHidden': 'Colonnes masquées',
    'columnsDisplayed': 'Colonnes affichées',
    'colNameReport': 'Nom colonne',
    'ranking': 'Rang',
    'grouping': 'Groupe',
    'filterList': 'Liste',
    'filterInput': 'Filtre',
    'pinning': 'Fixe',
    'nonePinning': 'Non',
    'startPinning': 'Gauche',
    'endPinning': 'Droite',

    // rowsPinned
    'rowPinnedLabel': 'Libellé ligne',
    'rowPinnedPosition': 'Position',
    'rowPinnedRank': 'Ordre',
    'rowPinnedCol': 'Colonne',
    'colSum': 'Somme',
    'colAvg': 'Moyenne',
    'colCount': 'Comptage',
    'colTop': 'Tête',
    'colBottom': 'Pied',
    'ChoiceColumn': 'Colonne',
    'ChoiceOper': 'Opération',
    'ChoicePosition': 'Position',
    'InputFormat': 'Format',
    // report card  
    'reportStatut': 'Statut rapport',
    'reportSample': 'Extraction test',
    'requestRefSelect': 'Requête',
    'menuParent': 'Menu parent',
    'menuRank': 'Menu position',
    'Choose one parent': 'Choisir un parent',
    'Choose one rank': 'Choisir le rang dans le menu',
    'HomeMenu': 'Accueil',
    'Choose one request': 'Choisir une requête',
    'Choose one statut': 'Choisir un statut',
    'Report label is required': 'Libellé de rapport requis',
    'Statut is required': 'Statut du rapport requis',
    'Request is required': 'Requête requise',
    'testReport': 'Test rapport : %0 ',
    // subReport
    'detailZoom': 'détail',
    'zoomPresent': 'Zoom activé',
    'zoomCols': 'Nombre de colonnes',
    'zoomRows': 'Nombre de lignes',   
    'reportName': 'Nom rapport',
    'reportInitialAction': 'Action',
    'foreignKeys': 'Clés étrangères',
    'limitationCol': 'Limitation colonnes',
    'dataRecall': 'Données rappel',
    // dashboard
    'dashboardRows': 'Nombre de lignes',
    'dashboardCols': 'Nombre de colonnes',
    'dashboardName': 'Nom ',
    'widgetName': 'Nom widget',
    'topWidget': 'Ligne départ',
    'leftWidget': 'Colonne de départ',
    'heightWidget': 'Hauteur (lignes)',
    'widthWidget': 'Largeur (colonnes)',
    'chartType': 'Type graphique',
    'defaultDisplay': 'Affiché par défaut',
    'updateFrequency': 'Fréquence MAJ',
    'updateFrequencyTip': 'Fréquence de la mise à jour en minutes',
    'generatedTime': 'Jeu de données :  %0',
    'editWidgets': 'Editer les widgets',
    'datetime': 'Afficher horodatage',
    'saveWidgets': 'Enregistrer configuration',
    // report-grid
    'LoadingReportData': 'Chargement des données - en cours ...',
    'LoadingReportConfig': 'Chargement configuration - en cours ...',
    'settingReportFilters': 'Elaboration des filtres - en cours ...',
    'settingReportPinnedRows': 'Mise en forme des lignes fixes - en cours ...',
    // entities menu
    'sharedEntities': 'Entité(s) communes',
    'MenuStructure': 'Organisation ',
    'MenuLabeling': 'Labelisation ',
    'menuName': 'Menu',
    'menuLabel': 'Libellé',
    'menuToolTip': 'Tooltip',
    // data table mjl_entities
    'entity ID': 'ID Entité',
    'entity': 'Entité',
    'entitylabel': 'Libellé',
    'entitydesc': 'Description',
    'datafilter': 'Filtre données',
    'supervisoremail': 'Mail superviseur',
    'extendedaccess': 'Extensions accès',
    'master': 'Master',
    'tags': 'tags',
    'models': 'Modèles documents',
    // data table mjl_usergroup
    'usersgroup': 'Groupes d\'utilisateurs',
    'usergroup': 'Groupe d\'utilisateurs',
    'usergrouplabel': 'Libellé étendu groupe d\'utilisateurs',
    'dataHabilitations': 'Habilitations ',
    'colName': 'Colonne',
    'dataCategory': 'Catégorie de données',
    'dataLevel': 'Niveau confidentialité',
    'Choose one category': 'Choisir une catégorie',
    // data table mjl_jobdbs
    'dbsLabel': 'Libellé',
    'dbsDoc': 'Source documentaire',
    'dbsEngine': 'Moteur',
    'dbsServer': 'Serveur',
    'dbsPort': 'Port',
    'dbsName': 'Nom',
    'dbsUser': 'Utilisateur',
    'dbsPwd': 'Mot de passe',
    'accessGrp': 'Groupe(s)',
    'accessEntities': 'Entité(s)',
    'updateDate': 'Mise à jour',

    // data table mjl_jobrequests
    'Requests': 'Requêtes',
    'requestLabel': 'Libellé ',
    'requestEngine': 'Moteur',
    'dbsId': 'Id datasource',
    'requestVersion': 'Version',
    'requestContent': 'Contenu',
    'requestDefault': 'Défaut',
    'docRequest': 'Requête documentaire',
    'docDbsLabel': 'Source de données',
    'docPath': 'Chemin',
    'docFilename': 'Nom du fichier',
    'docName': 'Nom du document',

    // data table mjl_jobRequestLabel

    'requestRef': 'Ref requête',
    'checkLabel': 'Label genérique',
    'colHeader': 'Entête colonne',
    'colToolTip': 'Libellé étendu',
    'colAttributes': 'Format particulier',
    'labelList': 'Liste libellés',
    'specialFormat': 'Formats particuliers',
    // data table mjl_jobReports
    'Reports': 'Rapports',
    'reportLabel': 'Libellé ',
    'reportPagination': 'Pagination ',
    'reportDocument': 'Documentaire ',
    'reportDashboard': 'Tableau de bord',
    'reportVersion': 'Version ',
    'colType': 'Type',
    'colFmt': 'Format',
    'fmtDate': 'Date',
    'fmtHour': 'Heure',
    'fmtTimeStamp': 'Horodatage',
    'fmtInteger': 'Entier',
    'fmtDec2': 'Decimal 2',
    'frmtZeroLleftInt5': 'Zéro 5',
    'frmtZeroLleftInt6': 'Zéro 6',
    'fmtDecCurrency2': 'Monétaire 2 Dec',
    'fmtIntCurrency': 'Monétaire entier',
    'fmtPercent': 'Pourcent',
    'Choose one format': 'Choisir un format',
    'Reports validation': 'Validation rapports',
    'reportAccept': 'Valider',
    'reportReject': 'Refuser',
    'Report Area': 'Espace reporting',
    // param_actionsref,
    'brandRef': 'Réf marque',
    'actionrefType': 'Nature action',
    'actionrefLabel': 'Libellé action',
    'suppliesLabel': 'Libellé',
    'actionTask': 'Action',
    'billingUnit': 'Unité de facturation',
    'searching actions ref': 'Recherche actions',
    'no action ref found': 'Aucune référence trouvée',
    'recording action ref': 'Enregistrement référence action',
    // param_partsupplies,
    'updateRatesPartSupplie': 'Mise à jour prix unitaires',
    'addPartSupplies': 'Créer un nouvel item',
    'uploadPartSupplie': 'Téléverser un fichier de références',
    'paramType': 'Type',
    'paramTypeLabel': 'Type',
    'brandName': 'Marque',
    'paramId': 'Référence',
    'paramLabel': 'Libellé',
    'partsSuppliesLabel': 'Libellé',
    'paramUnit': 'Unité',
    'paramRate': 'Prix unitaire',
    'paramCurrency': 'Monnaie',
    'paramQuantity': 'Quantité',
    'part': 'Pièce',
    'supplie': 'Fourniture',
    'prestation': 'Prestation',
    'deleting part & supplie': 'Effacement pièce et fourniture',
    'searching part & supplie': 'Recherche pièces et fournitures',
    'no part or supplie found': 'Aucune pièce ou fourniture trouvée',
    'recording part or supplie ': 'Enregistrement pièce ou fourniture',
    'supply': 'Approvisionnement',
    'referential': 'Référentiel',
    'upload referential': 'Téléversement référentiel',
    'upload supply': 'Téléversement appro',
    'upload local supply': 'Appro local',
    'upload import supply': 'Appro import',
    'analysisFileTitle': 'Analyse fichier',
    'analysisFile': 'Analyse du fichier %0 en cours ...',
    'analysisDatas': 'Analyse données',
    'modifFiles': 'Modifier fichiers',
    'buildCrossreferences': 'Création des références croisées ... ',
    'buildCrossrequest' : 'Création des requêtes croisées ...',
    'ProcessTerminate': 'Traitement terminé',
    'line': 'ligne',
    'out of': 'sur',
    'nbErrorsMultiFiles': 'Nombre d\'erreurs',
    'nbRowsMultiFiles': 'Nombre lignes',
    'downloadErrorsMultiFiles': 'Télécharger fichier erreurs',
    'downloadRowsMultiFiles': 'Télécharger fichier',
    'extractData': 'Extraction des données',
    'analysisData': 'Vérification données obligatoires',
    'extractDataMainFile': 'Extraction données fichier principal',
    'extractDataSubFile': 'Extraction données fichier : %0',
    'sellingPriceCalculation': 'Calcul prix de vente',
    'buildWhereClauseFileTitle': 'Construction clauses extraction',
    'buildWhereClauseFile': 'Construction clauses extraction fichier : %0',
    'buildCrossReferences': 'Construction références croisées',
    'verifCrossReferencesTitle' : 'Vérification des références croisées ...',
    'verifCrossReferences' : 'Vérification des références croisées en cours ...',
    'uploadMultiProcessTitle': 'Téléversement des fichiers',
    'uploadMultiProcess': 'Téléversement des fichiers en cours ...',
    'uploadMultiFilesOKTitle': 'Téléversement des fichiers',
    'uploadMultiFilesOK': 'Téléversement terminé',
    'readingLines': 'Lignes détectées',
    'colFile': 'Colonne fichier',
    'bddName': 'Colonne bdd',
    'hsCode': 'Code douanier SH',
    'paramFamily': 'Famille',
    'paramSubFamily': 'Sous famille',
    'paramCountry': 'Pays origine',
    'paramState': 'Etat origine',
    'columnsBddCrossRef': 'Références croisées',
    'currencyTarget': 'Monnaie cible',
    'incomingCurrency': 'Monnaie import',
    'exchangeRate': 'Taux de change',
    'columnsBddUpload': 'Correspondance bdd',
    'columnsBddList': 'Colonnes Bdd',
    'uploadFileName': 'Nom du fichier',
    'file exists': 'Fichier déjà sélectionné',
    'upload File parts & supplies': 'Téléchargement du fichier',
    'upload file OK': 'Chargement fichier %0 : %1 ',
    'register action KO': 'Enregistrement fichier %0 action %1 en erreur ',
    'upload file KO': 'Erreur chargement fichier %0 : %1 ',
    'control file content': 'Contrôle fichier',
    'partsSuppliesAdded': '%0 produit(s) ajouté(s)',
    'partsSuppliesUpdated': '%0 produit(s) mis à jour',
    'partsSuppliesDeleted': '%0 produit(s) supprimé(s)',
    'partSupplieSearchForm': 'Recherche références pièces fournitures & prestations',
    'partsSuppliesSelected': 'Références pièces fournitures & prestations sélectionnées',
    'partsSuppliesFounded': 'Références pièces fournitures & prestations trouvées',
    'addPartSupplieList': 'Ajouter à la liste',
    // update rates
    'updateRatesTitle': 'Mise à jour prix unitaires',
    'processRates': 'Mise à jour',
    'brandRefs': 'Références',
    'percentRate': 'Pourcentage',
    'increaseRates': 'Augmentation',
    'reductionRates': 'Diminution',
    'Process update': 'Lancement procédure mise à jour',
    'End process update': 'Procédure mise à jour terminée',
    'Process update OK': 'Process OK.  %0 élément(s) mis à jour',
    'Process update KO': 'Process KO.',
    'OK to process ?': 'OK pour exécution ?',
    // param_tasksref,
    'tasksReferenced': 'Tâches référencées',
    'CreateReferencedTask': 'Créer tache référencée',
    'Refresh': 'Actualiser liste',
    'remove from the list': 'Retirer de la liste',
    'create action': 'Créer action',
    'taskrefLabel': 'Libellé tâche',
    'fournitureLabel': 'Libellé',
    'jobLabel': 'Libellé travail',
    'taskSN': 'Numéros de série',
    'partRef': 'Référence pièce',
    'searching tasks ref': 'Recherche tâches',
    'no task ref found': 'Aucune référence trouvée',
    'recording task ref': 'Enregistrement tâche référencée',
    'deleting task ref': 'Effacement tâche référencée',
    'supplies&parts': 'Fournitures & pièces', 
    'labor': 'Main d\'oeuvre', 
    'works': 'Travaux',
    'check list': 'Check list',
    'check list empty': 'Créer une tâche spécifique ou choisir une tache référencée',
    'allEquipments': 'Tous équipements',
    'equipmentsSelected': 'Equipements',
    'liter': 'Litre',
    'meter': 'Mètre',
    'Check': 'Vérif',
    'Exchange': 'Changer',
    'ExchFirst': '1er Changer', 
    'taskrefQuantity': 'Quantité',
    'taskrefUnit': 'Unité',
    'taskrefDuration': 'Durée',
    'taskrefComment': 'Observations',
    'actionsReferenced': 'Actions référencées',
    'actionsSelected': 'Actions sélectionnées',
    'quantity': 'Quantité',
    'duration': 'Durée',
    'unitPrice': 'P U',
    'extPrice': 'P T',
    'available': 'Disponible',
    'totalDuration': 'Durée totale',
    'serial numbers': 'Numéros de série',
    'allSN': 'Tous les NS',
    'snFrom': 'De',
    'snUpto': 'Jusqu\'à',
    'jobPud': 'MAJ constructeur',
    'revision': 'Révision',
// WKS - parentTaskRef
    'taskRefParent': 'tâche parente',
// WKS  checkList
    'realized': 'Réalisé',
    'checked': 'Vérifié',
    'unchecked': 'Non Vérifié',
    'changed': 'Changé',
    'not_changed': 'Non changé',
    'additionalTasks': 'Autres travaux',
    'taskParts': 'Pièces',
    'taskDescription': 'Description',
    'taskDuration': 'Durée / heures',
    'taskComment': 'Commentaire',    
// WKS output stock
    'ouputStockTab': 'Sorties stock',
    'outputStock': 'Sortie stock',
    'inStock': 'En stock',
    'movementStock': 'Mouvement',
    'movementStockAction': 'Action',
    'outputSt': 'Sortie',
    'inputSt': 'Entrée',
    'loadingParts': 'Chargement de la liste des pièces  en cours ',
    'NOT_RESERVED': 'Commande non réservée',
    'BOOKING_REF': 'Référence réservation : ', 
    'orderRefTitle': 'Référence commande : ',
// WKS - wksAnchorages
    'anchorages': 'Mouillages',
    'anchorage': 'Mouillage',
    'anchorageCountry': 'Pays',
    'anchorageState': 'Etat',
    'anchorageCity': 'Ville',
    'anchorageName': 'Nom',
    'anchorageWintering': 'Hivernage',
    'anchoragePosition': 'Position Lat/Lng',
    'anchoragePositionCoordError': 'Position erronnée',
    'searching anchorages': 'Recherche mouillages',
    'no anchorage found': 'Aucun  mouillage trouvé',
    'searching anchorage': 'Recherche mouillage',
    'new anchorage': 'Nouveau mouillage',
    'update anchorage': 'Mise à jour mouillage',
    'Location': 'Localisation',
    'geoLocation': 'Geo Localisation ',
    'subsidiaryOf': 'Succursale de',
    'recording subsidiary': 'Enregistrement succursale',
    'removing subsidiary': 'Effacement succursale',
    // upload
    'upload File anchorages': 'Téléchargement du fichier',
    'anchoragesAdded': '%0 port(s) ajouté(s)',
    'anchoragesUpdated': '%0 port(s) mis à jour',
    'anchoragesDeleted': '%0 port(s) supprimé(s)',
// calculation algo
    'localPrice': 'PV produit local',
    'importedPrice': 'PV produit importé',
    'purchasePriceET': 'Prix d\'achat HT',
    'purchasePriceIT': 'Prix d\'achat TTC',
    'testingValue': 'Valeur de test',
    'dataLabel': 'Libellé donnée',
    'dataRef': 'Num donnée', 
    'operande': 'Opérande',
    'addition': 'Addition',
    'substraction': 'Soustraction',
    'multiplication': 'Multiplication',
    'division': 'Division',
    'numberValue': 'Valeur',
    'adding condition': 'Ajouter une condition',
    'adding expression': 'Ajouter une expression',
    'condExpression': 'Condition',
    'validAlgo': 'Validation',
    'testAlgo': 'Test algo',
    'previous oper': 'Oper précéd',
    'countryOrigin': 'Pays origine',
    'duties': 'Frais de douanes',
    'economicAreaEntity': 'Zone économique entité',
    'economicAreaOrigin': 'Zone économique origine',
// WKS - Menu
    'wks_administration': 'Administration',
    'wks_parameters': 'Paramétrage',
    'wks_repositories': 'Référentiels',
    'wks_customers': 'Clients',
    'wks_subcontractors': 'Sous traitants',
    'wks_suppliers': 'Fournisseurs',
    'wks_equipments': 'Equipements clients',
    'boats': 'Bateaux',
    'jets': 'Jets',
    'trailers': 'Remorques',
    'engines': 'Moteurs',
    'Customers': 'Clients',
    'Subcontractors': 'Sous Traitants',
    'Suppliers': 'Fournisseurs',
    'wks_entity': 'Admin entité',
    'wks_specifications': 'Spécifications contructeurs',
    'param_worksrates': 'Paramétrage spécialités',
    'param_partssupplies': 'Référentiel pièces et fournitures',
    'param_actionsref': 'Paramétrage actions',
    'param_tasksref': 'Paramétrage tâches',
    'associated_task': 'Tâche associée',
    'param_rh_planning': 'Planning RH',
    'wks_jobs': 'Travaux',
    'wks_quotations': 'Devis',
    'wks_works': 'Travaux',
    'wks_worksOptions': 'Dossiers',
    'wks_assignments': 'Assignation travaux',
    'wks_checklists': 'Check listes',
    'wks_invoices': 'Factures',
    'wks_invoicing': 'Facturation',
    'wks_appointments_list': 'Liste demandes',
    'wks_planning': 'Planning RDV',
// WKS - entity
    'accountCash': 'Numéro de compte comptable comptant',
    'accountFmt': 'Format numéro de compte comptable',
    'reportingActivate': 'Reporting actif',
    'entityDataPurge': 'Délai de purge des données inactives (mois)',
    'entityScales': 'Barêmes tarifaires',
    'entityCurrency': 'Monnaie',
    'no models': 'Aucun modèle',
    'Choose format': 'Choisir le format internationnal',
    'usersLanguages': 'Langues utilisateurs',
    'internationalFormat': 'Format international',
    'subsidiaries': 'Succursales',
    'entityPosition': 'Geo localisation',
    'entityTz': 'Fuseau horaire',
    'quotationActivation': 'Activation devis',
    'invoicingActivation': 'Activation facturation',
    'invoicingNumber': 'Numérotation',
    'activity': 'Activité',
    'generalSettings': 'Paramètres généraux',
    'workshop': 'Atelier',
    'concierge': 'Conciergerie',
    'accounting': 'Comptabilité',
    'location': 'Localisation',
    'customerOutstandingDefault': 'Encours client par défaut',
    'maxBdd': 'Max go bdd',
    'maxWebdav': 'Max go webdav',
    'maxSms': 'Max SMS / mois',
    'maxNotifs': 'Max notifs / mois',
    'paginationActivation': 'Activation pagination',
    'paginationLabel': 'Libellé pagination',
    'paginationFormat': 'Format pagination',
    'paginationPosV':  'Position V',
    'pagingationPosH': 'Position H',
    'sales price calculation': 'Calcul prix de vente',
    'local price': 'Produit local',
    'imported price': 'Produit importé',
    'stock management api': 'Gestion stocks',
    'accounting api': 'Comptabilité',
    'notifications api': 'Notifications',
    'customers api': 'Clients',
    'smtpServerParams': 'Paramètres serveur',
    'smtpServer': 'Serveur smtp',
    'smtpPort': 'Port',
    'smtpConnectSecurity': 'Type connexion',
    'smtpUserAuthentification': 'Authentification',
    'smtpUser': 'Utilisateur',
    'smtpPasswd': 'Mot de passe',
    'smtpParamResponse': 'Réponse',
    'smtpFrom': 'Email émetteur',
    'smtpReplyTo': 'Email de réponse',
    'smtpNoreply': 'Expression non réponse',
    'smtpDebug': 'Option debuggage',
    'smtpStorage': 'Stockage des envois',
    'storePrefix': 'Préfixe chemin stockage',
    'storeWorks': 'Messages travaux',
    'storeAppointments': 'Messages RDV',
    'storeRH': 'Messages RH',
    'storeQuotation': 'Messages devis',
    'storeInvoice': 'Messages factures',
    'storeAdvance': 'Messages acomptes',
    'storeCustomer': 'Messages clients',
    'storeRevisions': 'Messages révisions',
    'storeSmtp': 'Messages Smtp',
    'smtpTest': 'Test SMTP',
    'error to send mail': 'Erreur d\'envoi du mail de test',
    'send mail error': 'Erreur envoi mail',
    'test smtp OK': 'Test envoi smtp OK',
    'send mail OK': 'Envoi mail OK',
    'invoiceAppendix': 'Annexe facture', 
    'creditnoteAppendix': 'Annexe avoir', 
    'backPage': 'Fonds de page', 
    'quotationAppendix': 'Annexe devis',
    'modelInvoice': 'Modèle facture',
    'modelQuotation': 'Modèle devis',
    'modelCreditnote': 'Modèle avoir',
// WKS manufacturer specifications
    'loading loading manufacturers specifications': 'Chargement spécifications constructeurs',
    'manufacturers specifications': 'Spécifications constructeurs',
    'specFiletype': 'Type fichier',
    'specFileversion': 'Version',
    'specFilename': 'Nom fichier',
    'specStatut': 'Statut',
    'doUpload': 'Téléversement',
    'waitingUpload': 'Attente téléversement',
    'waitingExtractText': 'Attente extraction texte',
    'waitingRegex': 'Attente extraction données',
    'waitingVerifRegex': 'Attente verif data',
    'waitingInjectProcess': 'Attente injection data',
    'waitingVerifInjection': 'Attente verif injection',
    'uploadProcessTitle': 'Téléversement du fichier',
    'uploadProcess': 'Téléversement du fichier en cours ...',
    'uploadFileOKTitle': 'Téléversement du fichier',
    'uploadProcessDone': 'Terminé',
    'fileUploaded': 'Téléversé',
    'historyDate': 'Date traitement',
    'recordedDate': 'Date enregistrement',
    'downloadFile': 'Télécharger le fichier',
    'addFile': 'Ajouter un fichier',
    'badFileName': 'Fichier sélectionné %0. Fichier attendu : %1 ',
    'removeFileTitle': 'Suppression fichier',
    'removefileLabel': 'Supprimer le fichier %0 ?',
    'removefileLabelKO': 'Echec suppression fichier %0',
    'extractText': 'Extraire texte',
    'processingRegex': 'Extraire data',
    'validRegex': 'Validation data',
    'injectProcess': 'Injection BDD',
    'validInjection': 'Validation BDD',
    'new job label': 'Nouveau label',
    'extractText_OK': 'Extraire texte fichier %0 ?',
    'processingRegex_OK': 'Extraire data fichier %0 ?',
    'validRegex_OK': 'Validation data fichier %0 ?',
    'injectProcess_OK': 'Injection BDD fichier %0 ?',
    'validInjection_OK': 'Validation BDD fichier %0 ?',
    'extractText_PROGRESS': 'Extraire texte fichier %0 en cours ...',
    'processingRegex_PROGRESS': 'Extraire data fichier %0 en cours ...',
    'validRegex_PROGRESS': 'Validation data fichier %0 en cours ...',
    'injectProcess_PROGRESS': 'Injection BDD fichier %0 en cours ...',
    'validInjection_PROGRESS': 'Validation BDD fichier %0 en cours ...',
    'extractText_DONE': 'Extraire texte fichier %0 terminé !!',
    'processingRegex_DONE': 'Extraire data fichier %0 terminé !!',
    'validRegex_DONE': 'Validation data fichier %0 terminé !!',
    'injectProcess_DONE': 'Injection BDD fichier %0 terminé !!',
    'validInjection_DONE': 'Validation BDD fichier %0 terminé !!',
    'extractText_KO': 'Extraire texte fichier %0 en erreur',
    'processingRegex_KO': 'Extraire data fichier %0 en erreur',
    'validRegex_KO': 'Validation data fichier %0 en erreur',
    'injectProcess_KO': 'Injection BDD fichier %0 en erreur',
    'validInjection_KO': 'Validation BDD fichier %0 en erreur',
    'verifList': 'Vérification liste',
// WKS - Specjobs
    'loading specificationsImportList': 'Chargement spécifications importées ', 
    'specificationsImportList': 'Liste spécifications importées ', 
    'readingImports': 'Lecture des imports',
    'searching imports': 'Recherche des imports en cours ...',
    'no import found ': 'Aucun import trouvé. Erreur %0 ',
    'jobNumber': 'Numéro de Job',
    'jobLabel1': 'Libellé',
    'jobPage': 'Page',
    'jobMajor': 'Majeur',
    'jobNormal': 'Normal',
    'jobLevel': 'Niveau',
    'jobNotes': 'Commentaires',
    'specType': 'Type job',
    'periodicityHours': 'Heures',
    'periodicityMonths': 'Mois',
    'frequency': 'Fréquence',
    'jobParts': 'Pièces',
    'jobAction': 'Actions',
    'replace': 'Changer',
    'replaceFirstYear': 'Changer 1ere année',
    'check': 'Vérifier',
    'brandLabel': 'Libellé',
    'valid specification': 'Validation specification',
    'lost data': 'Voulez vous continuer ? Les données seront perdues !!',
    'validation in progress': 'Validation en cours',
    'validation KO': 'Validation en erreur : %0 ',
    'validation OK': 'Validation terminée',
    'removeImports': 'Suppression specification',
    'remove specification': 'Confirmez-vous la suppression du job %1 da la page %0 ? ',
    'removeSpecification': 'Suppression specification',
    'remove in progress': 'Suppression en cours',
    'remove KO': 'Suppression en erreur : %0 ',
    'remove OK': 'Suppression terminée',
    'duplicated brand ref': 'Référence fournisseur %0 dupliquée',
    'duplicated work': 'Référence travaux dupliquée',
// APIS
    'toolName': 'Nom',
    'toolVersion': 'Version',
    'toolProtocol': 'Protocole',
    'authentification': 'Authentification',
    'tooluser': 'Utilisateur',
    'toolkey': 'Clé',
    'toolusername': 'Utilisateur',
    'toolpwd': 'Pwd',
    'toolkeyname': 'Nom clé',
    'toolkeyvalue': 'Valeur clé',
    'toolServerUrl': 'URL',
    'toolCustomerNwsIdField': 'Champ id Nws',
    'toolCustomerNwsId': 'Id Nws',
    'toolCustomerNwsCode': 'Code Nws',
    'toolCustomerNwsCodeField': 'Champ code Nws',
    'toolServerPort': 'Port',
    'toolServerArgs': 'Args',
    'toolServiceName': 'Nom service',
    'toolServiceMethod': 'Methode',
    'toolServiceUrl': 'Url',
    'toolServiceArgs': 'Args',
    'toolJwtServerUrl': 'Url',
    'toolJwtServerPort': 'Port',
    'toolJwtServerArgs': 'Args',
    'toolserver': 'Serveur',
    'toolServices': 'Services',
    'toolauth2': 'Authentification 2',
    'activateJwt': 'Activation Jwt',
    'headers': 'En têtes',
    'headerKey': 'Clé',
    'headerValue': 'Valeur',
    'args': 'Arguments',
    'testsApi': 'Tests',
    'create': 'Création',
    'read': 'Lecture',
    'readByRef': 'Lecture référence',
    'update': 'Mise à jour',
    'list': 'Liste',
    'stock': 'En stock',
    'movementAdd': 'Mouvement ajouter',
    'movementLess': 'Mouvement retirer',
    'orderAdd': 'ajouter commande',
    'orderValidate': 'Valider commande',
    'orderClose': 'Clôturer commande',
    'orderRemove': 'Supprimer Commande',
    'notAuthorised': 'Non autorisé',
    'service': 'Service',
    'dateTest': 'Date test',
    'testApi': 'Test Api',
    'stockManagement': 'Gestion stocks',
    'notifications': 'Notifications',
    'customers': 'Clients',
    'stockManagementMes': 'Gestion stocks %0 - %1',
    'invoicingMes': 'Facturation %0 - %1',
    'notificationsMes': 'Notifications %0 - %1',
    'accountingMes': 'Comptabilité %0 - %1',
    'customersMes': 'Clients %0 - %1',
    'inProgress': 'En cours',
// WKS param worksrates
    'workCode': 'Code de spécialité',
    'workLabel': 'Libellé spécialité',
    'workUnit': 'Unité de facturation',
    'workPricing': 'Liste de prix',
    'searching works rates': 'Recheche spécialité',
    'unitary price per contract': 'Prix unitaire par marché',
    'no work rate found': 'Aucune spécialité trouvée',
    'unit': 'Unité',
    'hour': 'Heure',
    'day': 'Journée',
    'week': 'Semaine',
    'month': 'Mois',
    'quarter': 'trimestre',
    'semester': 'Semestre',
    'year': 'Année',
    'Choose workunit': 'Choisir unité facturation',
    'error recording': 'Erreur enregistrement',
    'recording entity': 'Enregistrement Entité',
    'recording work rate': 'Spécialité',
    'error loading': 'Erreur chargement',
    'loading entity ': 'Chargement entité',
    'deleting work rate': 'Effacement spécialité',
// WKS - bsn
    'searching customers': 'Recherche des clients',
    'no customer': 'Aucun client trouvé',
    'searching subcontractors': 'Recherche des sous traitants',
    'no subcontractor': 'Aucun sous traitant trouvé',
    'searching suppliers': 'Recherche des fournisseurs',
    'no supplier': 'Aucun fournisseur trouvé',
    'deleting customers': 'Suppression client',
    'deleting subcontractors': 'Suppression sous traitant',
    'deleting suppliers': 'Suppression fournisseur',
    'deleting contact customer': 'Suppression contact client',
    'deleting contact subcontractor': 'Suppression contact sous traitant',
    'deleting contact supplier': 'Suppression contact fournisseur',
    'deleting contact anchorage': 'Suppression contact mouillage',
    'OK alert': 'OK',
    'company': 'Société',
    'administration': 'Administration',
    'person': 'Personne',
    'professional': 'Professionnel',
    'individual': 'Particulier',
    'BsnCard': 'Fiche ',
    'BsnAddresses': 'Adresses ',    
    'BsnContacts': 'Contacts',
    'administrative': 'Administratifs',
    'contracts': 'Contrats',
    'guarantees': 'Garanties',
    'quotations': 'Devis',
    'invoices': 'Factures',
    'invoicing': 'Facturation',
    'other docs': 'Autres docs',
// wks_businness
    'bsnEntity': 'Entité',
    'bsnCountry': 'Nationalité',
    'bsnNature': 'Nature',
    'bsnParent': 'Parent',
    'bsnType': 'Type',
    'bsnTax': 'Fiscalité',
    'customerOutstanding': 'Encours autorisé',
    'corporateName': 'Raison sociale',
    'bsnCivility': 'Civilité',
    'bsnFirstName': 'Prénom',
    'bsnLastName': 'Nom',
    'bsnName': 'Nom',

    'bsnAccount': 'Comptabilité',
    'bsnCashPay': 'Comptant',
    'scaleName': 'Barême tarifaire',
    'bsnCoef': 'Coefficient',
    'taxableVat': 'TVA',
    'idVat': 'Identifiant TVA',
    'customsRegistration': 'Douanes',
    'customsId': 'Identifiant douanes',
    'freeZone': 'Zone franche',
    'freeZoneId': 'Identifiant zone franche',
    'days': 'Jours',
    'bsnDefferedPaiement': 'Paiement',
    'bsnDelayDays': 'Délai',
    'delayValidity': 'Délai validité',
    'invoiceEmit': 'Emission facture',
    'endMonth': 'Fin de mois',
    'OrderPrePaid': 'A la commande',
    'receptMaterials': 'A réception du matériel',
    'materialsInstallation': 'A l\'installation du matériel',
    'completionWork': 'A la fin des travaux',
    'afterInvoice': 'Après facturation',
    'inAccount': 'En compte',
    'orderNumber': '#',
    'controlPercent': 'Contrôle',
    'bsnNatIdentity': 'Id National',
    'bsnNatRegister': 'Registre national',
    'bsnAddress': 'Adresse',
    'bsnZipcode': 'CP',
    'bsnCity': 'Ville',
    'bsnState': 'Etat',
    'bsnEmail': 'Email',
    'bsnTel': 'Tel',
    'bsnFunction': 'Fonction',
    'bsnHabilquote': 'Devis',
    'bsnHabilbill': 'Facture',
    'bsnRole': 'Rôle',
    'stdCreation': 'Création',
    'stdLastupdate': 'MAJ',
    'stdDisable': 'Inactivé',
    'loading Customers': 'Chargement clients',
    'loading Suppliers': 'Chargement fournisseurs',
    'loading Subcontractors': 'Chargement sous traitants',
    'balance': 'Engagement',
    // address 
    'purchase department': 'Service achats',
    'juridical department': 'Service juridique', 
    'financial department': 'Service financier',
    'technical department': 'Service technique',
    'identity': 'Identité',
    'Fonction dept': 'Fonction',
    'fonctionDept': 'Fonction',
    'Select dept': 'Service',
    'selectDept': 'Service',
    'Select country': 'Choisir un pays',
    'Select apps': 'Notifications',
    'cntCivility': 'Civilité',
    'cntFirstname': 'Prénom',
    'cntLastname': 'Nom',
    'addrElem1': 'Destinataire',
    'addrElem2': '',
    'addrElem3': 'Adresse',
    'addrElem4': '>',
    'addrElem5': '>',
    'addrElem6': '>',
    'addrelem1': 'Destinataire',
    'addrelem2': '',
    'addrelem3': 'Adresse',
    'addrelem4': '>',
    'addrelem5': '>',
    'addrelem6': '>',
    'area': 'Lieu',
    'block': 'Bloc',
    'street_name': 'Rue',
    'house_number': 'Numéro',
    'paci_number': 'PACI',
    'AREA': 'Lieu',
    'BLOCK': 'Bloc',
    'STREET_NAME': 'Rue',
    'HOUSE_NUMBER': 'Numéro',
    'PACI_NUMBER': 'PACI',
    'pobox': 'Boite Postale',
    'district': 'District',
    'zipcode': 'CP',
    'addrElem7': '',
    'city': 'Ville',
    'country': 'Pays',
    'countryCode': '',
    'state': 'Etat',
    'denomination': 'Dénomination',
    'fullName': 'Nom complet',
    'FEIN': 'FEIN',
    'SIRET': 'SIRET',
    'addressPosition': 'Geo localisation',
    'phoneNumber': 'Num',
    'phonePrefix': 'Préfixe',
    'phoneType': 'Type',
    'phoneDesk': 'Bureau',
    'phoneCell': 'Mobile',
    'phoneCellWhatsApp': 'Mobile & WhatsApp',
    'phoneHome': 'Domicile',
    'phoneOther': 'Autre',

    'emailAddress': 'Adresse email',
    'emailType': 'Type',
    'prof': 'Professionnel',
    'perso': 'Personnel',
    'profID': 'Id professionnel',
    'individualID': 'Id Individuel',
    'unknow': 'Inconnu',

    'recording customers': 'Enregistrement des clients',
    'recording subcontractors': 'Enregistrement des sous traitants',
    'recording suppliers': 'Enregistrement des fournisseurs',
    'customer exists': 'Ce client existe déjà',
    'subcontractor exists': 'Ce sous traitant existe déjà',
    'supplier exists': 'Ce fournisseur exists déjà',
    // WKS - wks-equipment
    'id': 'Identifiant',
    'stdEntity': 'Entité',
    'equipId': 'Id Equipement',
    'ownerId': 'Id Propriétaire',
    'ownerName': 'Propriétaire',
    'equipPartOf': 'Partie de',
    'equipImmat': 'Immatriculation',
    'equipType': 'Type Equipement',
    'equipTypeLabel': 'Type Equipement',
    'equipDesign': 'Désignation',
    'equipCountry': 'Pays',
    'equipBrand': 'Marque',
    'equipModel': 'Modèle',
    'equipYear': 'Année',
    'equipName': 'Nom unité',
    'equipSN': 'NS',
    'equipAddInfo': 'Info additionnelles',
    'equipHours': 'Heures',
    'equipHoursDate': 'Date relevé heures',
    'equipValid': 'Validé',
    'otherData': 'Autres données',
    'hull': 'Coque',
    'outboard engine': 'Hors bord',
    'inboard engine': 'In-bord',
    'work history': 'Historique travaux',
    'revisions': 'Révisions',
    'revisionsHours': 'Heures',
    'revisionsMonths': 'Mois',
    'revisionsTask': 'Job',
    // WKS - usage hours
    'histoHours': 'Heures',
    'histoMonths': 'Mois',
    'histoDate': 'Date',
    'histoRevision': 'Révision',
    'Control values hours': 'Contrôle des heures',
    'hours lt': 'Heures saisies inférieures aux heures précédentes : %0 ==> %1',
    'hours gt': 'Heures saisies supérieures aux heures suivantes : %0 ==> %1',
    'Control values date': 'Contrôle des dates',
    'date lt': 'Date saisie inférieure à la date précédente : %0 ==> %1',
    'date gt': 'Date saisie supérieure à la date suivante : %0 ==> %1',
    'usage hours': 'Heures d\'utilisation',
    'no hours': 'Aucun relevé d\'heures',
    'register error': 'Erreur  d\'enregistrement',
    // WKS Jobs / equipments
    'boat': 'Bateau',
    'engine': 'Moteur',
    'jet': 'Jet',
    'trailer': 'Remorque',
    'electronic & navigation': 'Electronique & navigation',
    'appliances': 'Electro-ménager',
    'fourniture': 'Aménagement',
    'chandlery': 'Accastillage',
    'generator': 'Groupe électrogène',
    'air conditioning system': 'Climatisation',
    'gyro stabilizer': 'Stabilisateur',
    'welding jobs': 'Travaux de soudures',
    'carpentry jobs': 'Travaux de menuiserie',
    'fiberglass jobs': 'Travaux de résine',
    'upholstery jobs': 'Travaux de tapisserie',
    'painting jobs': 'Travaux de peinture',
    'machine shop jobs': 'Travaux de tournage-fraisage',
    'electrical jobs': 'Travaux électriques',
    'storage': 'Stockage',
    'mechanical jobs': 'Travaux de mécanique',
    'all jobs type': 'Tous travaux',
    'hauling & towing': 'Manutention & remorquage',
    'search': 'Recherche',
    'searching boats': 'Recherche de bateaux',
    'searching jets': 'Recherche de Jets',
    'searching trailers': 'Recherche de Remorques',
    'searching engines': 'Recherche de Moteurs',
    'no boat found': 'Aucun Bateau trouvé',
    'no jet found': 'Aucun Jet trouvé',
    'no trailer found': 'Aucune Remorque trouvée',
    'no engine found': 'Aucun Moteur trouvé',
    'Choose owner': 'Choisir un propriétaire',
    'Choose brand': 'Choisir une marque',
    'Choose model': 'Choisir un modèle',
    'Choose year': 'Choisir une année',
    'add equipment': 'Créer',
    'search equipment': 'Rechercher',
    'all equipments': 'Liste des',
    'Searching boat': 'Recherche bateau',
    'Searching jet': 'Recherche jet',
    'Searching trailer': 'Recherche remorque',
    'Searching engine': 'Recherche moteur',
    'New boat': 'Nouveau bateau',
    'New jet': 'Nouveau jet',
    'New trailer': 'Nouvelle remorque',
    'New engine': 'Nouveau moteur',
    'recording boat': 'Enregistrement du bateau',
    'recording jet': 'Enregistrement du jet',
    'recording engine': 'Enregistrement moteur',
    'recording trailer': 'Enregistrement de la remorque',
    'Equipments': 'Equipements',
    'motorisation inboard': 'In-bord', 
    'motorisation outboard': 'Hors-bord', 
    'motorisation jet': 'Jet', 
    'propulsion': 'Propulsion',
    'Choose propulsion': 'Choisir la propulsion',   
    'motorsConfiguration': 'Configuration motorisation',
    'Choose motorsConfiguration': 'Choisir la configuration',
    'sails': 'Voiles',
    'single installation': 'Mono',
    'dual installation': 'Double',
    'triple installation': 'Tri',
    'quad installation': 'Quadri',
    'quint installation': 'Quintuple',
    'Port side': 'Bâbord', 
    'Starboard': 'Tribord',
    'center': 'Central',
    'Port side outside': 'Bâbord extérieur', 
    'Port side inside': 'Bâbord interieur', 
    'Starboard inside': 'Tribord extérieur', 
    'Starboard outside': 'Tribord intérieur',
    'equipBoatManeuvring': 'Motorisation manoeuvres',
    'motorType': 'Type moteur',
    'Choose motorType': 'Choisir type de moteur',
    'motorPosition': 'Position moteur',
    'commissioningDate': 'Mise en service',
    'thruster motor': 'Moteur manoeuvre',
    'stern thruster': 'Moteur de poupe',
    'bow thruster': 'Moteur d’étrave',
    'ownerContact' : 'Contact',
    'equipHitch': 'Attelage',
    'Choose equipHitch': 'Choisir un attelage',
    'ball hitch' : 'Boule',
    'ring hitch' : 'Anneau',
    'hitchSizes': 'Taille attelage',
    'Choose hitchSizes': 'Choisir taille attelage',
    'axles': 'Essieux',
    'Choose axles': 'Nombre essieux',
    'tires': 'Pneus',
    'Choose tires': 'Taille pneus',
    'rims': 'Jantes',
    'nuts': 'Ecrous',
    'Choose rims': 'Taille Jantes',
    'sizes' : 'Taille',
    'trailer axles': 'Essieux',
    'trailer tires': 'Pneus',
    'trailer rims': 'Jantes',
    'equipBoatHomeport': 'Port d\'attache',
    'equipBoatAnchorage': 'Mouillage',
    'equipBoatMooring': 'Amarrage',
    'equipBoatWintering': 'Hivernage',
    'portPool': 'Bassin',
    'portPontoon': 'Ponton',
    'portRing': 'Anneau',
// wks-changeStatut
    'changeWorkStatut': 'Changement de statut',
    'workStatutCur': 'Statut actuel',
    'workStatutNew': 'Nouveau statut',
    'confirmChangeStatut': 'Confirmation changement statut',
    'changeWorkStatutLabel': 'De %0 vers %1',
// wks_docrepository
    'docs': 'Documents',
    'new document': 'Nouveau document',
    'update document': 'Mise à jour document',
    'searching document': 'Recherche document',
    'docBrand': 'Marque',
    'docModel': 'Modèle',
    'docTags': 'Classification',
    'docYear': 'Année modèle',
    'docComment': 'Commentaires',
    'docVersion': 'Version',
    'technical': 'Technique',
    'user': 'Utilisateur',
    'technical doc': 'Doc technique',
    'user doc': 'Doc utilisateur',
    'Choose tag': 'Choisir une catégorie',
    'searching documents': 'Recherche de Documents',
    'no doc found': 'Aucun Document trouvé',
    'Searching docs': 'Recherche Documents',
    'recording doc': 'Enregistrement document',
    'Upload document': 'Téléchargement du document',
    'upload doc': 'Téléchargement document',
    'deleting docs': 'Effacement document',
    'uploadDate': 'Date téléchargement',
// WKS - wks-works
    'workIdCopied': 'Identifiant travaux copié !',
    'loadingWorks': 'Chargement des travaux',
    'owner': 'Propriétaire',
    'equipment': 'Equipement',
    'tasks': 'Travaux',
    'description': 'Description',
    'intervention': 'Intervention',
    'newCustomer': 'Nouveau client',
    'existsCustomer': 'Client existant',
    'newEquipment': 'Nouvel équipement',
    'existsEquipment': 'Equipement existant',
    'loadingCustomer': 'Chargement des clients en cours ...',
    'loadingEquipments': 'Chargement des équipements client en cours ...',
    'loadingSubEquipments': 'Chargement des équipements en cours ...',
    'loadingBoats': 'Chargement des bateaux en cours ...',
    'Equipment attached to a boat': 'Equipement attaché à un bateau ?',
    'search equipment customer': 'Recherche équipement client',
    'no equipment found': 'Aucun équipment trouvé',
    'search boats customer': 'Recherche bateaux clients',
    'no boat found customer': 'Aucun bateau trouvé',
    'workId': 'Identifiant',
    'workRef': 'Ref',
    'workMainEquipName': 'Unité',
    'workCustomerName': 'Client',
    'workPrevious': 'Travaux liés',
    'workObject': 'Objet',
    'workComment': 'Commentaire',
    'mooringPort': 'Port de Mouillage',
    'locationWorkshop': 'Atelier',
    'locationMooring': 'Mouillage',
    'locationHomePort': 'Port Attache',
    'locationOther': 'Autre mouillage',
    'locationHomeOwner': 'Domicile',
    'criticalityState': 'Niveau urgence',
    'dispatchtab': 'Diffusion',
    'workCriticalityLabel': 'Niveau urgence',
    'availableLabel': 'Pièces',
    'immobilized': 'Immobilisé',
    'priority': 'Prioritaire',
    'normal': 'Normal',
    'back_job': 'Mauvaise\nexécution',
    'planning intervention': 'Planning intervention',
    'workStartDate': 'Date début',
    'workEndDate': 'Date fin',
    'CreateTask': 'Créer une tâche',
    'CreateSpecificTask': 'Créer tâche spécifique',
    'workRecordedDate': 'Enregistrement',
    'workTicket': 'Ref maintenance',
    'workStatut': 'Statut',
    'no work found': 'Aucun travail touvé',
    'searching works': 'Recherche de travaux',
    'recording work': 'Enregistrement travaux',
    'All statuts': 'Tous les statuts',
    'undefined': 'Non défini',
    'appointment': 'Demande',
    'appointment request': 'Demande',
    'elaboration': 'Elaboration',
    'completed': 'Complété',
    'submitted': 'Soumis',
    'accepted': 'Accepté',
    'refused': 'Refusé',
    'assigned': 'Assigné',
    'in-progress': 'En cours',
    'finished': 'Terminé',
    'closed': 'Clôturé',
    'invoiced': 'Facturé',
    'historized': 'Historisé',
    'cancelled': 'Annulé',
    'stand-by': 'En attente',
    'statusJournal': 'Suivi',
    'recordedTrack': 'Date',
    'userLogged': 'Utilisateur',
    'numberTrack': 'Ordre',
    'photos': 'Photos',
    'linkPhotos': 'Lien externe photo',
    'generatingLinkPhoto': 'Génération du lien photos en cours ',
    'linkPhotoGenerated': 'Lien généré',
    'new photo': 'Dépôt photos',
    'new doc': 'Dépôt documents',
    'Choose equipment': 'Choisir équipement',
    'Choose task': 'Choisir une tâche',
    'Choose moment': 'Choisir le moment',
    'BEFORE': 'Avant',
    'DURING': 'En cours',
    'AFTER': 'Fin',
    'sequenceTime': 'Heure',
    'sequenceDate': 'Date',
    'sequencePhotos': 'Séquence photos',
    'sequenceLabel': 'Séquence',
    'loadingPhotos': 'Chargement des photos en cours ',
    'workdocLegend': 'Légende',
    'workdocLabel': 'Libellé',
    'workdocFilename': 'Nom fichier',
    'workdocType': 'Type',
    'quote': 'Devis',
    'quoteNews': 'Emis',
    'quoteValidated': 'Validés',
    'quoteSubmitted': 'Soumis',
    'quoteRefused': 'Refusés',
    'quoteApproved': 'Approuvés',
    'quoteInprogress': 'En cours',
    'submiting': 'Soumettre',
    'computingQuoteTitle': 'Calcul du devis',
    'computingQuoteInProgress': 'Calcul du devis en cours ...',
    'computingQuoteInError': 'Erreur lors du calcul du devis : \n%0', 
    'No more data': 'Aucune donnée',
    'the file is under study': 'Dossier en étude',
    'study completed': 'Dossier en étude',
    'submitted to client': 'Dossier soumis au client',
    'accepted by the customer': 'Dossier accepté par le client',
    'assigned to a worker (workshop, on site, subcontractor, etc.)': 'Dossier assigné',
    'the work is in stand by': 'Travaux suspendus',
    'the work is in progress': 'Travaux en cours',
    'the intervention is finished': 'Travaux terminés',
    'If the planned work is not carried out, it is rejected': 'Dossier clôt',
    'transmitted for invoicing': 'Transmis pour facturation',
    'placed in the equipment history': 'Dossier historisé',
    'close': 'Fermer',
    'validated': 'Valider',
    'approved': 'Approuvé',
    'waitingAdvance': 'Attente acompte',
    'disapproved': 'Annuler approbation',
    'unvalidated': 'Annuler validation',
    'unvailable parts': 'Pièces indisponibles',
    'all parts availables': 'Pièces disponibles',
    'phonesList': 'Téléphones',
    'emailList': 'Emails',
    'terms of payment': 'Conditions de paiement',
    'terms of payment customer': 'Conditions de paiement client',
    'terms of payment specifics': 'Conditions de paiement spécifiques',
    'financial conditions': 'Conditions financières',
    'financial conditions customer': 'Conditions financières client',
    'financial conditions specifics': 'Conditions financières spécifiques',
    'specificsConditions': 'Conditions spécifiques',
    'scheduling of payments': 'Echancier',
    'customerFinancials': 'Conditions client',
    'customerConditions':  'Conditions client',
    'numberPayments': 'Nombre de versements',
    'termPayment': 'Terme',
    'delayPayment': 'Délai',
    'percentPayment': ' % ',
    'save customer': 'Valider client',
    'change customer': 'Changer de client',
    'change equipement': 'Changer d\'équipement',
    'save equipement': 'Valider équipement',
    'save main equipement': 'Valider équipement principal',
    'choice boat': 'Choisir un bateau',
    'choose type equipment': 'Choisir un type d\'équipement',
    'choice equipment': 'Choisir un équipement',
    'create equipment': 'Créer un nouvel équipement',
    'no condition' : 'Aucune condition',
    'workPositionCoord': 'Lat / lng',
    'save intervention': 'Valider intervention',
    'data no validate': 'Données non validées',
    'confirmCancel': 'Confirmation sortie',
    'calStart': 'Date début',
    'calEnd': 'Date fin',
    'calActor': 'Intervenant',
    'billing pending': 'Facturation en attente',
    'tasksBox': 'Gestion des tâches',
    'removeTask': 'Supprimer la tache',
    'valuing parts': 'Valorisation des pièces à partir du stock',
    'verifyStock': 'Vérification stock',
    'verifyStockParts': 'Vérification stock pièces',
    'verifyStockPartsDone': 'Vérification stock pièces: Terminée',
    'verifyStockPartsFailed': 'Vérification stock pièces : Erreur',
    'reserveStockParts': 'Réserver dans le stock',
    'removeOrderParts': 'Annuler la réservation',
    'reserveStockPartsTitle': 'Réservation dans le stock',
    'removeOrderPartsTitle': 'Annulation réservation %0',
    'noPartsFounded': 'Aucune pièce trouvée',
// wks accounting
    'accOperation': 'Opération',
    'accLabel': 'Libellé',
    'accStatut': 'Statut',
    'accAmount': 'Montant',
    'paid': 'Payé',
    'unpaid': 'Non payé',
    'customer outstanding': 'Encours client',
    'outstanding committed': 'Engagé',
    'authorized': 'Autorisé',
    'current': 'Actuel',
    'remove row': 'Suppression',
    'save row': 'Validation',
    'error remove': 'Erreur sur suppression',
    'validationError': 'Erreur sur validation',
    'balance_inprogress': 'Calcul en cours',
    'balance_failure': 'Calcul en erreur',
    'paymentRef': 'Référence paiement',
    'payment': 'Paiement',
    'adding row': 'Ajouter une ligne',
    'editing row': 'Editer la ligne',
    'delete row': 'Supprimer la ligne',
    'detail row': 'Détail de la ligne',
    'submit row': 'Valider la ligne',
    'accNwsref': 'Référence interne',
    'workPosition': 'Situation dossier',
// works assignments
    'Works assignments': 'Assignation travaux',
// google maps
    'Click the map to get Lat/Lng': 'Click pour obtenir Lat/lng',
    'Search Box': 'Recherche',
// appointments
    'wks_appointments': 'RDV',
    'Appointments': 'Statuts des dossiers',
    'client': 'Client',
    'equipments': 'Equipements',
    'equipmentLocation': 'Localisation',
    'equipmentPosition': 'Position',
    'equipmentJobsite': 'Localisation travaux ',
    'askedComment': 'Commentaires',
    'appointmentStatut': 'Statut',
    'creationDate': 'Date création',
    'lastUpdateDate': 'Dernière MAJ',
    'appointmentsOtherdata': 'Autres données',
    'customer': 'Client',
    'appointmentRef': 'Demande Ref',
    'appointmentId': 'Demande Id',
    'type': 'Type',
    'brand': 'Marque',
    'model': 'Modèle',
    'serialNumber': 'Numéro de série',
    'nbHoursOfUse': 'Heures d\'usage',
    'equipmentNum': 'Equipement num',
    'askedService': 'Service',
    'askedServiceHours': 'Heures',
    'companyName': 'Entreprise',
    'nbEquipments': 'Nombre equipements',
    'mailAddress': 'Mail',
    'appointment card': 'Dossier de demande',
    'appointment location': 'Localisation',
    'tracking': 'Suivi',
    'REGULAR_SERVICE': 'Révision',
    'quotation_waiting': 'Attente',
    'quotation_sent': 'Devis envoyé',
    'quotation_accepted': 'Devis accepté',
    'quotation_paid': 'Devis payé',
    'quotationJournal': 'Suivi devis',
    'invoicingJournal': 'Suivi facturation',
    'appointment_fixed': 'RDV fixé',
    'work_assigned': 'Travail assigné',
    'workAssigned': 'Travail assigné',
    'appointmentFixed': 'RDV fixé',
    'work_started': 'Travail commencé',
    'work_done': 'Travail terminé',
    'final_invoice': 'Facturation finale',
    'final_payment': 'Paiement final',
    'work_closed': 'Clôturé',
    'statut': 'Statut',
    'AllStatuts': 'Tous les statuts',
    'selectedStatut': 'Statut sélectionné',
    'addingStatut': 'Ajouter un statut',
    'trackingStatut': 'Statut',
    'appointmentDate': 'Date RDV',
    'appointmentTime': 'Heure RDV',
    'workerName': 'Technicien',
    'workStartedDate': 'Date Début',
    'workStartedTime': 'Heure Début',
    'workDoneDate': 'Date Fin',
    'workDoneTime': 'Heure Fin',
    'appointmentsSearch': 'Recherche RDV',
    'noAppointments': 'Aucun RDV',
    'New appointment': 'Nouveau RDV',
    'verif check': 'Vérif check-list',
    'missingParts': 'Check',
    'checkListControl': 'Contrôle check liste',
// appointments planning
    'Appointments planning': 'Planning RDV',
    'Pending requests': 'Demandes en attente',
    'phoneNum': 'Téléphone',
// wks - calendar
    'eventDetail': 'Détail évènement',
    'eventTitle': 'Titre',
    'eventType' : 'Type',
    'eventDescription': 'Description',
    'eventComment': 'Commentaire',
    'eventStart': 'Début',
    'eventEnd': 'Fin',
    'eventOutside': 'Chez',
    'appointmentCustomer': 'RDV Client',
    'appointmentWork': 'RDV Travaux',
    'rhPlanning': 'Planning RH',
    'technician': 'Intervenant',
    'workshopJob': 'Atelier',
    'restDay': 'Congés',
    'vehicleMaintenance': 'Maintenance véhicule',
    'partSupply': 'Approvisionnment',
    'appointmentTimeError': 'Erreur RDV',
    'appointmentStartTimeError': 'Le créneau horaire de début est déjà retenu !!',
    'appointmentEndTimeError': 'Le créneau horaire de fin est déjà retenu !!',
    'appointmentChangeTime': 'Déplacement RDV',
    'appointmentDelete': 'Suppression RDV',
    'appointmentNewDate': 'Nouveau RDV',
    'referencedRequest': 'Référence demande ',
    'scheduledFor': 'Prévu pour',
    'appointmentimetable': 'Confirmation horaires',
    'appointmentOldTime': 'Ancien horaire',
    'appointmentNewTime': 'Nouvel horaire',
    'appointmentSeparator': ' --> ',
    'detailedSheet': 'Fiche détaillée',
    'RH': 'RH',
    'HR': 'RH',
    'detailAppointment': 'Detail',
    'editAppointment': 'Editer',
    'deleteAppointment': 'Supprimer',
    'calendar_valid': '<div>RDV validé. <b>Référence : %0</b></div>',
    'calendar_not_valid': '<div>RDV non validé <b>Référence : %0</b></div>',
    'calendar_validation': 'Validation RDV en cours ...',
// location
    'locationJobsite': 'Lieu intervention',
    'locationAddress': 'Adresse',
    'locationWintering': 'Hivernage',
    'locationAnother': 'Autre port',
    'homeAnchoragePort': 'Port attache',
    'anchoragePort': 'Mouillage',
    'winteringPort': 'Hivernage',
    'anotherPort': 'Autre port',
    'harbor': 'Port',
    'pool': 'Bassin',
    'pontoon': 'Ponton',
    'ring': 'Anneau',
    'berth': 'Emplacement',
    'landPark': 'Emplacement parking',
    'marinaName': 'Marina',
// invoicing  - quotation
    'invoiceRecap': 'Recap facture',
    'quotationRecap': 'Recap devis',
    'recapGen': 'Récap générale',
    'suppliesBT': 'Pièces HT',
    'laborBT': 'MO HT',
    'totalBT': 'Total HT',
    'totalEquipBT': 'Total équipement(s) HT',
    'totalGenBT': 'Total Général HT',
    'totalGenTax': 'Total Général Taxes',
    'totalGenWT': 'Total Général TTC',
    'suppliesTax': 'Taxes Pièces',
    'laborTax': 'Taxe MO',
    'totalTax': 'Total taxes',
    'suppliesWT': 'Pièces TTC',
    'laborWT': 'MO TTC',
    'totalWT': 'Total TTC',
    'taxProfile': 'Profil taxes',
    'vat': 'TVA',
    'default': 'défaut',
    'recap_supplies': 'Pièces',
    'recap_labor': 'MO',
    'recap_total': 'Total',
    'recap_totalisation': 'Totalisation',
    'totalOtherBT': 'Autres éléments HT',
    'otherRowLabel': 'Libellé',
    'otherRowAmount': 'Montant',
    'totalFinancialBT': 'Conditions financières',
    'rounding': 'Arrondi',
    'discount': 'Remise',
    'discountCash': 'Comptant',
    'surcharge': 'Majoration',
    'rowLabelCplt': 'Complément libellé',
    'rowBase': 'Arrondir à',
    'rowPercent': 'Pourcentage',
    'invoice': 'Facture',
    'creditnote': 'Avoir',
    'advance': 'Acompte',
    'quotation': 'Devis',
    'error invoicing': 'Erreur émission de facture',
    'error advance': 'Erreur émission de l\'avance',
    'error creditnote': 'Erreur émission de l\'avoir',
    'Ok to emit invoice': 'OK pour émettre la facture ?',
    'Ok to emit creditnote': 'OK pour émettre l\'avoir ?',
    'Ok to emit advance': 'OK pour émettre l\'acompte ?',
    'Quotation emit': 'Devis terminé',
    'invoice emit': 'Facture émise',
    'credit note emit': 'Avoir émis',
    'advance emit': 'Demande acompte émise',
    'quotation_inprogress': 'Devis en cours ...',
    'invoicing_inprogress': 'Facturation en cours ...',
    'creditnote_inprogress': 'Avoir en cours ...',
    'advance_inprogress': 'Emission acompte en cours ...',
    'multiplePayments': 'Echéancier',
    'update works': 'Mise à jour travaux',
    'process finished': 'Document terminé',
    'creditnotes': 'Avoirs',
    'advances': 'Acomptes',
    'emitDate': 'Emission',
    'sendAdvance': 'Emettre',
    'sendCreditnote': 'Emettre',
    'comments': 'Commentaires',
    'totalCreditnoteWT': 'Avoir TTC',
// common - EmailFormComponent
    'recipient': 'Destinataire',
    'copy': 'Copie',
    'ccopy': 'Copie cachée',
    'fromField': 'De',
    'subjectField': 'Sujet',
    'bodyField': 'Contenu',
    'emailform': 'Email',
    'sendEmail': 'Envoi email',
// messages : 
    'statutDetail': 'Détail statut',
    'messageDetail': 'Détail du message',
    'sendBy': 'Expedideur',
    'recipientsMes': 'Destinataire(s)',
    'ccMes': 'Copie',
    'sendDate': 'Date Envoi',
    'mediaMes': 'Media',
    'objectMes': 'Objet',
    'bodyMes': 'Contenu',
    'metaData': 'Meta données',
};

export const MONTHS_FR_TRANS = {
    1: 'Jan', 2: 'Fev', 3: 'Mar', 4: 'Avr', 5: 'Mai', 6: 'Juin', 7: 'Jul', 8: 'Aout', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec'
};

export const DAYS_FR_TRANS = {
    su: 'Dim', mo: 'Lun', tu: 'Mar', we: 'Mer', th: 'Jeu', fr: 'Ven', sa: 'Sam'
};


export const LOCAL_FR_FMT = {
    dateFormat: 'DD/MM/YYYY',
    timeFormat: 'DD/MM/YYYY HH:mm:ss', 'Location': 'Localisation',
    showTodayBtn: true,
    todayBtnTxt: 'Ce jour',
    firstDayOfWeek: 'mo',
    sunHighlight: true,
    showWeekNumbers: true,
    ageMin: 18,
    ageMax: 99,
};
export const AG_GRID_FR = {
    // for filter panel
    page: 'Page',
    more: 'Plus',
    to: 'à',
    of: 'de',
    next: 'Suivant',
    last: 'Dernier',
    first: 'Premier',
    previous: 'Précédent',
    loadingOoo: 'Chargement ...',

    // for set filter
    selectAll: 'Tout sélectionner',
    searchOoo: 'Chercher ...',
    blanks: 'Blanc',

    // for number filter and text filter
    filterOoo: 'Filtrer ...',
    applyFilter: 'Appliquer filtre...',
    equals: 'Egal',
    // notEquals: 'Non Egal',
    notEqual: 'Non Egal',
    // for number filter
    lessThan: 'Plus petit que',
    greaterThan: 'Plus grand que',
    lessThanOrEqual: 'Plus petit ou égal',
    greaterThanOrEqual: 'Plus grand ou égal',
    inRange: 'fourchette',

    // for text filter
    contains: 'Contient',
    notContains: 'Ne contient pas',
    startsWith: 'Commence par ',
    endsWith: 'Finit par',

    // filter conditions
    andCondition: 'ET',
    orCondition: 'OU',

    // the header of the default group column
    group: 'Groupe',

    // tool panel
    columns: 'Colonnes',
    filters: 'Filtres',
    rowGroupColumns: 'Colonne pivot',
    rowGroupColumnsEmptyMessage: 'déplacer ici pour grouper',
    valueColumns: 'Valeur colonnes',
    pivotMode: 'Mode pivot',
    groups: 'groupes',
    values: 'valeurs',
    pivots: 'pivots',
    valueColumnsEmptyMessage: 'déplacer ici pour aggréger',
    pivotColumnsEmptyMessage: 'déplacer ici pour pivot',
    toolPanelButton: 'panneau outils',

    // other
    noRowsToShow: 'Aucune ligne',

    // enterprise menu
    pinColumn: 'Colonne fixe',
    valueAggregation: 'Valeur aggrégée',
    autosizeThiscolumn: 'auto dimensionner cette colonne',
    autosizeAllColumns: 'Auto dimensionner toutes les colonnes',
    groupBy: 'groupé par',
    ungroupBy: 'non groupé par',
    resetColumns: 'recharger les colonnes',
    expandAll: 'Tout ouvrir',
    collapseAll: 'tout fermer',
    toolPanel: 'Panneau outils',
    export: 'Exporter',
    csvExport: 'Format CSV',
    excelExport: 'Format Excel (.xlsx)',
    excelXmlExport: 'Format Excel (.xml)',

    // enterprise menu pinning
    pinLeft: 'Fixer à gauche <<',
    pinRight: 'Fixer à droite >>',
    noPin: 'Ne pas fixer <>',

    // enterprise menu aggregation and status bar
    sum: 'Somme',
    min: 'Min',
    max: 'Max',
    none: 'Aucun',
    count: 'Comptage',
    average: 'moyenne',
    filteredRows: 'Lignes filtérées',
    selectedRows: 'Lignes sélectionnées',
    totalRows: 'Lignes totales',
    totalAndFilteredRows: 'Lignes totales et filtrées',

    // standard menu
    copy: 'Copie',
    copyWithHeaders: 'Copie avec entêtes',
    ctrlC: 'ctrl-C',
    paste: 'Coller',
    ctrlV: 'ctrl-V'
};
