import { throwError as observableThrowError, BehaviorSubject, Observable, Subject } from 'rxjs';
import { EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { ClipboardService } from 'ngx-clipboard';
import { RessourcesService } from '../../../job/common/services/ressources.service';
import { TranslateService } from '../../../services/translate.service';
import { environment } from '../../../../environments/environment';
import { CommonMethods } from '../../common/tools/commonMethods';
import { MenuWksModel, MeasurementList } from '../models/wks-common.model';
import { WksBusinessModel, WksContactsModel, PaiementsTerm, AccountingModel, AccountingBalance } from '../models/wks-business.model';
import { WksWorksModel, WksWorksDocs, WksEquipmentsRequest, WksEquipmentUsage, PhotoData } from '../models/wks-works.model';
import { Manufacturers, StepProcess } from '../models/wks-manufacturers.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-clipboard";
import * as i3 from "../../common/services/ressources.service";
import * as i4 from "../../../services/translate.service";
// import WKS_PARAMS from '../../../../assets/data/wks_params.json';
// import CONTENTS_MIME from '../../../../assets/data/contents-mime.json';
export class WksCentralService {
    constructor(httpClient, _clipboardService, _ressourceService, _translate) {
        this.httpClient = httpClient;
        this._clipboardService = _clipboardService;
        this._ressourceService = _ressourceService;
        this._translate = _translate;
        this.wksMenuItemsEvents = new BehaviorSubject(undefined);
        this.wksMenuButtonEvents = new BehaviorSubject(undefined);
        this.onChangeStatut = new EventEmitter();
        this.onRegisterValid = new EventEmitter();
        this.taskWksEvent = new EventEmitter();
        this.onSearchList = new EventEmitter();
        this.onSearchDoc = new EventEmitter();
        this.onSearchAnchorage = new EventEmitter();
        this.onSearchParamWorkRate = new EventEmitter();
        this.onSearchParts = new EventEmitter();
        // wksContentsMime: any;
        this.wksContentsMimeMap = new Map();
        this.onDestroy = new Subject();
        //  const locCountry = wksParamsJson[countryKey] as CountryDef;
    }
    loadContentsMime() {
        // return this.http.get('/getallfiles');
        const localHttpHeaders = this.httpHeaders;
        const urlCur = `${environment.baseUrl}/config/contentsMime`;
        return this.httpClient.get(urlCur, {
            headers: localHttpHeaders,
            observe: 'response'
        });
    }
    loadLocalDef(countryKey) {
        const urlService = `${environment.baseUrl}/wks/jobs/getLocalDef/?` + 'countrykey=' + countryKey;
        const localHttpHeaders = this.httpHeaders;
        return this.httpClient
            .post(urlService, undefined, {
            headers: localHttpHeaders,
            observe: 'response'
        });
    }
    loadCountryFmt(countryKey) {
        const urlService = `${environment.baseUrl}/wks/jobs/getCountryFormat/?` + 'countrykey=' + countryKey;
        const localHttpHeaders = this.httpHeaders;
        return this.httpClient
            .post(urlService, undefined, {
            headers: localHttpHeaders,
            observe: 'response'
        });
    }
    loadWksParams() {
        // return this.http.get('/getallfiles');
        const localHttpHeaders = this.httpHeaders;
        const urlCur = `${environment.baseUrl}/config/wksParams`;
        return this.httpClient.get(urlCur, {
            headers: localHttpHeaders,
            observe: 'response'
        });
    }
    setContentsMime(_contentsMime) {
        // const jsonMimeArray: {mimeType: string; keyType: string}[] = JSON.parse(_contentsMime);
        // const jsonMimeArray: {mimeType: string; keyType: string}[] = _contentsMime;
        for (const mimeItem of _contentsMime) {
            // console.log (mimeItem);
            this.wksContentsMimeMap.set(mimeItem.keyType, mimeItem.mimeType);
        }
        // this.wksContentsMime = JSON.parse(JSON.stringify(_contentsMime));
    }
    setWksParams(_wksParams, _entityActivity) {
        this.wksParamsStr = JSON.stringify(_wksParams);
        this.wksParamsJson = JSON.parse(this.wksParamsStr);
        this.workStatutsEntity = [];
        if (_entityActivity === 'workshop') {
            this.workStatutsEntity = this.wksParamsJson.workStatuts.workshop;
        }
        if (_entityActivity === 'concierge') {
            this.workStatutsEntity = this.wksParamsJson.workStatuts.concierge;
        }
    }
    // https://www.npmjs.com/package/ngx-clipboard
    copyToClipboard(text) {
        this._clipboardService.copyFromContent(text);
    }
    setHeaders(_headers) {
        this.httpHeaders = _headers;
    }
    getContentMime(_fileExtension) {
        return this.wksContentsMimeMap.get(_fileExtension);
        // return this.wksContentsMime[_fileExtension];
    }
    ctrlApisTestList(toolApiVersions, toolVersion, toolTests) {
        const toolTestsRef = [];
        for (const toolVersionItem of toolApiVersions) {
            if (toolVersionItem.toolVersion === toolVersion) {
                for (const toolService of toolVersionItem.toolServices) {
                    const toolTest = {
                        apiService: toolService.name,
                        menuTest: toolService.menuTest,
                        authorised: toolService.authorised,
                        initSession: toolService.initSession,
                        previousServices: [],
                        nextServices: [],
                        alternativeService: '',
                        statut: 'NT',
                        testDate: undefined,
                        toolDatas: []
                    };
                    toolTestsRef.push(toolTest);
                }
            }
        }
        toolTestsRef.sort((obj1, obj2) => {
            return obj1.menuTest > obj2.menuTest ? 1 : -1;
        });
        // adding new test item 
        let iTemCur = 0;
        for (const toolTestRefItem of toolTestsRef) {
            let isFound = false;
            for (const toolTestItem of toolTests) {
                if (toolTestItem.apiService === toolTestRefItem.apiService) {
                    toolTestItem.menuTest = toolTestRefItem.menuTest;
                    isFound = true;
                    break;
                }
                else {
                    continue;
                }
            }
            if (!isFound) {
                toolTests.splice(iTemCur, 0, toolTestRefItem);
            }
            iTemCur++;
        }
        // suppress old tests items
        iTemCur = 0;
        for (const toolTestItem of toolTests) {
            let isFound = false;
            for (const toolTestRefItem of toolTestsRef) {
                if (toolTestItem.apiService === toolTestRefItem.apiService) {
                    isFound = true;
                    break;
                }
            }
            if (!isFound) {
                toolTests.splice(iTemCur, 1);
            }
            iTemCur++;
        }
        return toolTests;
    }
    /*================================================================== WKS_PARAMS --*/
    getGoogleAPIKey() {
        return this.wksParamsJson.google.apikey;
    }
    getTasksHeadingsList() {
        return this.wksParamsJson.tasksheadings;
    }
    getComponentsList() {
        return this.wksParamsJson.typologies.components;
    }
    getComponentsHours() {
        return this.wksParamsJson.typologies.hours;
    }
    getEquipmentsList() {
        return this.wksParamsJson.typologies.equipments;
    }
    getPropulsionsList() {
        return this.wksParamsJson.typologies.propulsions;
    }
    getMotorsconfigList() {
        /*const wksParamsJsonLocal = JSON.parse(this.wksParamsStr);
        return wksParamsJsonLocal.typologies.motorsconfig;*/
        return this.wksParamsJson.typologies.motorsconfig;
    }
    getThrustersconfigList() {
        return this.wksParamsJson.typologies.thrustersconfig;
    }
    // PaiementsTerm
    getPaiementsTerm() {
        return this.wksParamsJson.paiementsTerm;
    }
    getPaginationFormats() {
        return this.wksParamsJson.paginationFormat;
    }
    getTrailersOptions() {
        return this.wksParamsJson.trailersoptions;
    }
    getDocClassification() {
        return this.wksParamsJson.typologies.docclassification;
    }
    getBillingUnits() {
        return this.wksParamsJson.billingUnits;
    }
    getTaskActions() {
        return this.wksParamsJson.taskActions;
    }
    getUnitsQuantity() {
        return this.wksParamsJson.unitsquantity;
    }
    getCriticality() {
        return this.wksParamsJson.criticality;
    }
    getWorkStatuts() {
        // return this.wksParamsJson.workStatuts;
        return this.workStatutsEntity;
    }
    getWksSupportedRoles() {
        return this.wksParamsJson.supportedRoles;
    }
    getWksMaxDefaults() {
        return this.wksParamsJson.maxDefaults;
    }
    getPhonesType() {
        return this.wksParamsJson.communications.phonesType;
    }
    getNotifApp() {
        return this.wksParamsJson.communications.notificationsApp;
    }
    getWorkStatut(_label) {
        /*
        for (const workstatutCur of this.wksParamsJson.workStatuts) {
          if (workstatutCur.label === _label) {
            return workstatutCur;
          }
        }
        */
        for (const workstatutCur of this.workStatutsEntity) {
            if (workstatutCur.label === _label) {
                return workstatutCur;
            }
        }
        return null;
    }
    getIhmOption(optionName, workstatutCur) {
        let ihmOptionsCur;
        for (const optionCur of workstatutCur.ihmOptions) {
            if (optionCur.optionName === optionName) {
                ihmOptionsCur = optionCur;
            }
        }
        if (ihmOptionsCur === undefined) {
            ihmOptionsCur = {
                optionName: optionName,
                readOnly: true,
                display: false,
            };
        }
        return ihmOptionsCur;
    }
    getColumnsHeadersAnchoragesCsv() {
        return this.wksParamsJson.anchorages.columnsCsv;
    }
    getColumnsHeadersPartsSuppliesCsv() {
        return this.wksParamsJson.partsSupplies.columnsCsv;
    }
    getTypesPartsSupplies() {
        return this.wksParamsJson.partsSupplies.paramType;
    }
    getColumnsHeadersPartsReferentialCsv() {
        return this.wksParamsJson.partsReferential.columnsCsv;
    }
    getColumnsHeadersImportSupplyCsv() {
        return this.wksParamsJson.importSupply.columnsCsv;
    }
    getColumnsHeadersImportSupplyFinalCsv() {
        return this.wksParamsJson.importSupplyFinal.columnsCsv;
    }
    getColumnsHeadersLocalSupplyCsv() {
        return this.wksParamsJson.localSupply.columnsCsv;
    }
    getColumnsHeadersLocalSupplyFinalCsv() {
        return this.wksParamsJson.localSupplyFinal.columnsCsv;
    }
    getAlgoCalculationData() {
        return this.wksParamsJson.algoCalcul;
    }
    getApisParam(apiArg) {
        const apisList = this.wksParamsJson.APIS;
        let apiCur;
        for (const apiParam of apisList) {
            if (apiParam.apiName === apiArg) {
                apiCur = apiParam.apiTools;
            }
        }
        return apiCur;
    }
    setApisEntity(apisEntity) {
        if (apisEntity !== undefined) {
            for (const apiCur of apisEntity) {
                if (apiCur.apiName === 'stockManagement') {
                    this.stockManagementApi = apiCur.apiTool;
                    if (this.stockManagementApi.toolName === undefined) {
                        this.stockManagementApi = undefined;
                    }
                }
                else if (apiCur.apiName === 'accounting') {
                    this.accountingApi = apiCur.apiTool;
                    if (this.accountingApi.toolName === undefined) {
                        this.accountingApi = undefined;
                    }
                }
                else if (apiCur.apiName === 'notifications') {
                    this.notificationsApi = apiCur.apiTool;
                    if (this.notificationsApi.toolName === undefined) {
                        this.notificationsApi = undefined;
                    }
                }
                else if (apiCur.apiName === 'customers') {
                    this.customersApi = apiCur.apiTool;
                    if (this.customersApi.toolName === undefined) {
                        this.customersApi = undefined;
                    }
                }
                else if (apiCur.apiName === 'invoicing') {
                    this.invoicingApi = apiCur.apiTool;
                    if (this.invoicingApi.toolName === undefined) {
                        this.invoicingApi = undefined;
                    }
                }
            }
        }
    }
    getApiStockManagement() {
        return this.stockManagementApi;
    }
    getServicesStockManagement() {
        const apisDef = this.getApisParam('stockManagement');
        let toolApiCur;
        for (const apisDefItem of apisDef) {
            if (apisDefItem.toolName === this.stockManagementApi.toolName) {
                toolApiCur = apisDefItem;
                break;
            }
        }
        let toolServices;
        for (const toolVersionItem of toolApiCur.toolVersions) {
            if (toolVersionItem.toolVersion === this.stockManagementApi.toolVersion) {
                toolServices = toolVersionItem.toolServices;
            }
        }
        return toolServices;
    }
    getApiAccounting() {
        // const apisDef = this.getApisParam('accounting');
        return this.accountingApi;
    }
    getApiNotifications() {
        // const apisDef = this.getApisParam('notifications');
        return this.notificationsApi;
    }
    getApiCustomers() {
        // const apisDef = this.getApisParam('customers');
        return this.customersApi;
    }
    getApiinvoicing() {
        // const apisDef = this.getApisParam('invoicing');
        return this.invoicingApi;
    }
    getManufacturers() {
        return this.wksParamsJson.manufacturers;
    }
    getSpecificationsProcess() {
        return this.wksParamsJson.specificationsProcess;
    }
    getSchedulerClasses() {
        return this.wksParamsJson.scheduler.classes;
    }
    /*========================================== anchorages =======================*/
    loadAnchorages() {
        return new Promise((resolve, reject) => {
            this.getWksAnchoragesList()
                .subscribe(data => {
                const dataList = data.body;
                const listAnchorages = this.extractListAnchorages(dataList);
                resolve(listAnchorages);
            }, err => {
                console.log(err);
                if ((err.status === 500) || (err.status === 404)) {
                    console.log('loadAnchorages : ' + err.message);
                }
                reject(err.status);
            });
        });
    }
    extractListAnchorages(_dataList) {
        const anchoragesCacheList = [];
        const anchoragesWinteringList = [];
        for (const anchorageCur of _dataList) {
            const countryTmp = this._ressourceService.getCountry(anchorageCur.anchorageCountry);
            const anchorageCountryLabel = this._translate.getLabelCountry(this._translate.currentLang.toUpperCase(), countryTmp.commonName);
            const labelCur = {
                value: anchorageCur.id,
                label: anchorageCur.anchorageName + ' - ' + anchorageCur.anchorageCity + ' (' + anchorageCountryLabel + ')',
                otherData: anchorageCur.anchoragePosition,
            };
            anchoragesCacheList.push(labelCur);
            if (anchorageCur.anchorageWintering) {
                anchoragesWinteringList.push(labelCur);
            }
        }
        this.setWksAnchorages(anchoragesCacheList);
        this.setWksAnchoragesWintering(anchoragesWinteringList);
        return anchoragesCacheList;
    }
    setWksAnchorages(wksAnchorages) {
        this.wksAnchorages = wksAnchorages;
    }
    getWksAnchorages() {
        return this.wksAnchorages;
    }
    setWksAnchoragesWintering(wksAnchorages) {
        this.wksAnchoragesWintering = wksAnchorages;
    }
    getWksAnchoragesWintering() {
        return this.wksAnchoragesWintering;
    }
    /*================================================================== Wks Entity ----*/
    setWksEntityCur(_entityCur) {
        this.entityCur = _entityCur;
        // "nautikoNWS"
        if (this.entityCur.stdEntity === 'nautikoNWS') {
            return;
        }
        const entityOptions = JSON.parse(_entityCur.entityOptions);
        if (entityOptions === null) {
            return;
        }
        const quotationActivation = entityOptions.quotationActivation;
        if (quotationActivation !== undefined) {
            this.entityCur.isQuotationActive = quotationActivation;
        }
        const customerOutstanding = entityOptions.customerOutstanding;
        if (customerOutstanding !== undefined) {
            this.entityCur.customerOutstanding = customerOutstanding;
        }
        else {
            this.entityCur.customerOutstanding = 0;
        }
    }
    getWksEntityCur() {
        return this.entityCur;
    }
    getWksEntityHeadquarters() {
        const urlCur = `${environment.baseUrl}/wks/wksentity/headquarters/`;
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    getWksAllEntities() {
        const urlCur = `${environment.baseUrl}/wks/wksentity/findAll/`;
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    getWksEntitySubsidiaries(_entityId) {
        const urlCur = `${environment.baseUrl}/wks/wksentity/subsidiaries/?` + 'entityId=' + _entityId;
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    getWksEntity(_entity) {
        const urlCur = `${environment.baseUrl}/wks/wksentity/get/?` + 'entity=' + _entity;
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    // getParams
    getWksEntityInput(_entity) {
        const urlCur = `${environment.baseUrl}/wks/wksentity/getParams/?` + 'entity=' + _entity;
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    saveWksEntity(_entityCard, _action) {
        const urlCur = `${environment.baseUrl}/wks/wksentity/save/?` + 'action=' + _action;
        const body = JSON.stringify(_entityCard);
        return this.httpClient
            .put(urlCur, body, {
            headers: this.httpHeaders,
            observe: 'response',
        })
            .pipe(catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    testSmtp(stdEntity, _smtpCard) {
        const urlCur = `${environment.baseUrl}/wks/wksentity/testSmtp/?` + 'stdEntity=' + stdEntity;
        const body = JSON.stringify(_smtpCard);
        return this.httpClient
            .post(urlCur, body, {
            headers: this.httpHeaders,
            observe: 'response',
        })
            .pipe(catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    requestApis(stdEntity, apiName, serviceName, toolTestArgs) {
        const urlCur = `${environment.baseUrl}/wks/wksentity/requestApis/?` + 'stdEntity=' + stdEntity + '&apiName=' + apiName + '&serviceName=' + serviceName;
        const body = JSON.stringify(toolTestArgs);
        return this.httpClient
            .post(urlCur, body, {
            headers: this.httpHeaders,
            observe: 'response',
        })
            .pipe(catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    /*================================================================== Wks Business --*/
    // business/byid/
    getWksBusinessId(_entity, _id) {
        const urlCur = `${environment.baseUrl}/wks/business/byid/?` + 'entity=' + _entity + '&id=' + _id;
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    getWksBusinessList(_entity, _natureCur) {
        const urlCur = `${environment.baseUrl}/wks/business/?` + 'entity=' + _entity + '&natureCur=' + _natureCur;
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    /* getWksContactsList(_entity: string, _natureCur: string, _parent: string): Observable<HttpResponse<WksBusinessModel[]>> {
      
      const urlCur = `${environment.baseUrl}/wks/business/contacts/?` + 'entity=' + _entity + '&natureCur=' + _natureCur + '&parent=' + _parent;
     
      return this.httpClient.get<WksBusinessModel[]>(urlCur,
        {
          headers: this.httpHeaders,
          observe: 'response'
        })
        
        .pipe(
          // catchError(this.handleErrorWksBusinessList), );
          catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
          } ));
    }*/
    saveWksBusiness(_bsnCard, statutCall) {
        const urlCur = `${environment.baseUrl}/wks/business/savebsn/?` + 'action=' + statutCall;
        /*
            if (statutCall === 'createBsn') {
              urlCur = `${environment.baseUrl}/wks/business/savebsn/`;
            }
        */
        const body = JSON.stringify(_bsnCard);
        return this.httpClient
            .put(urlCur, body, {
            headers: this.httpHeaders,
            observe: 'response',
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    /*================================================================== Wks Equipment --*/
    searchWksEquipment(_equipCard) {
        const urlCur = `${environment.baseUrl}/wks/equipment/searchequipment/`;
        const body = JSON.stringify(_equipCard);
        return this.httpClient
            .post(urlCur, body, {
            headers: this.httpHeaders,
            observe: 'response',
        }).pipe(catchError(this.handleErrorObservable));
    }
    saveWksEquipment(_equipCard, actionType) {
        const urlCur = `${environment.baseUrl}/wks/equipment/saveequipment/?` + 'action=' + actionType;
        const body = JSON.stringify(_equipCard);
        return this.httpClient
            .put(urlCur, body, {
            headers: this.httpHeaders,
            observe: 'response',
        }).pipe(catchError(this.handleErrorObservable));
    }
    getWksEquipmentList(_entity, _equipType) {
        _equipType = CommonMethods.cleanURLString(_equipType);
        const urlCur = `${environment.baseUrl}/wks/equipment/?` + 'entity=' + _entity + '&equipType=' + _equipType;
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    /*
    getWksSubEquipmentList(_entity: string, _equipType: string, _mainEquip): Observable<HttpResponse<WksEquipmentModel[]>> {
      _equipType = CommonMethods.cleanURLString(_equipType);
      const urlCur = `${environment.baseUrl}/wks/equipment/?` + 'entity=' + _entity + '&equipType=' + _equipType+ '&mainEquip=' + _mainEquip;
     
      return this.httpClient.get<WksEquipmentModel[]>(urlCur,
        {
          headers: this.httpHeaders,
          observe: 'response'
        })
        
        .pipe(
          // catchError(this.handleErrorWksBusinessList), );
          catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
          } ));
    }
    */
    getWksEquipmentByEquipId(_entity, _id) {
        const urlCur = `${environment.baseUrl}/wks/equipment/equipid/?` + 'entity=' + _entity + '&id=' + _id;
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    // business/byid/
    getOwner(_entity, _id) {
        const urlCur = `${environment.baseUrl}/wks/business/byid/?` + 'entity=' + _entity + '&id=' + _id;
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    getWksEquipmentListOwner(_entity, _ownerId, _equipType) {
        const urlCur = `${environment.baseUrl}/wks/equipment/owner/?` + 'entity=' + _entity + '&ownerId=' + _ownerId + '&equipType=' + _equipType;
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    getWksEquipmentListBoat(_entity, _equipPartOf, _equipType) {
        const urlCur = `${environment.baseUrl}/wks/equipment/partof/?` + 'entity=' + _entity + '&equipPartOf=' + _equipPartOf + '&equipType=' + _equipType;
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    getWksSubEquipment(_entity, _equipmentList) {
        const urlCur = `${environment.baseUrl}/wks/equipment/subequip/?` + 'entity=' + _entity + '&equiplist=' + _equipmentList;
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    /*================================================================== Wks histo usage --*/
    getHistoUsage(_entity, _equipId) {
        const urlCur = `${environment.baseUrl}/wks/equipment/histoUsage/?` + 'entity=' + _entity + '&equipId=' + _equipId;
        const body = undefined;
        return this.httpClient.post(urlCur, body, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    saveHistoUsage(wksEquipmentUsage, _action) {
        const urlCur = `${environment.baseUrl}/wks/equipment/saveHistoUsage/?` + 'action=' + _action;
        const body = JSON.stringify(wksEquipmentUsage);
        return this.httpClient
            .put(urlCur, body, {
            headers: this.httpHeaders,
            observe: 'response',
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    getWksBrandsList(_entity) {
        const urlCur = `${environment.baseUrl}/wks/equipment/brands/?` + 'entity=' + _entity;
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    getWksModelsList(_entity, _brandName, _equipType) {
        _equipType = CommonMethods.cleanURLString(_equipType);
        const urlCur = `${environment.baseUrl}/wks/equipment/models/?` + 'entity=' + _entity + '&brandname=' + _brandName + '&equiptype=' + _equipType;
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    saveWksBrand(_brandCur, _action) {
        const urlCur = `${environment.baseUrl}/wks/equipment/savebrands/?` + 'action=' + _action;
        const body = JSON.stringify(_brandCur);
        return this.httpClient
            .put(urlCur, body, {
            headers: this.httpHeaders,
            observe: 'response',
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    /*======================================================================== Docs */
    /*
    getLinkSharing(_docCur: WksDocsModel, _dateCut: string, dataTmp: WksTmpData) {
      const linkValues = _dateCut + ';' + _docCur.docRef;
      // const keyHash = btoa(linkValues);
      const keyHash = CommonMethods.encryptAES(linkValues, dataTmp.dataCalc);
      // encodeURIComponent(keyHash.toString());
      const linkLocal = dataTmp.dataRef + 'AAA' + keyHash;
      const completeURL = `${environment.baseUrl}/wks/sharedLink/?` + 'doclink=' + linkLocal;
      const completeUrlEncoded = encodeURIComponent(completeURL);
      // console.log(completeUrlEncoded);
      return `${environment.baseUrl}/wks/sharedLink/?` + 'doclink=' + encodeURIComponent(linkLocal);
      
    }
    */
    getLinkPhotos(photoData, entity) {
        const urlCur = `${environment.baseUrl}/wks/getWaitingLinkPhotos/?` + 'application=wksPhotos&linkApp=photos&entity=' + entity;
        const body = JSON.stringify(photoData);
        return this.httpClient
            .post(urlCur, body, {
            headers: this.httpHeaders,
            observe: 'response',
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    getLinkSharing(_docCur) {
        const urlCur = `${environment.baseUrl}/wks/getWaitingLink/?` + 'application=wksDocs&linkApp=document';
        const body = JSON.stringify(_docCur);
        return this.httpClient
            .post(urlCur, body, {
            headers: this.httpHeaders,
            observe: 'response',
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    searchWksDoc(_equipCard) {
        const urlCur = `${environment.baseUrl}/wks/equipment/searchdoc/`;
        const body = JSON.stringify(_equipCard);
        return this.httpClient
            .post(urlCur, body, {
            headers: this.httpHeaders,
            observe: 'response',
        }).pipe(catchError(this.handleErrorObservable));
    }
    getWksDocListEquip(_entity, _equipType, _docBrand, _docModel) {
        const urlCur = `${environment.baseUrl}/wks/equipment/documents/?` + 'entity=' + _entity + '&equiptype=' + _equipType +
            '&docbrand=' + _docBrand + '&docmodel=' + _docModel;
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    getWksDocList(_entity) {
        const urlCur = `${environment.baseUrl}/wks/equipment/documents/?` + 'entity=' + _entity + '&equiptype=null&docbrand=null&docmodel=null';
        // "equiptype", "docbrand", "docmodel"
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    getWksDocListOwner(_entity, _ownerId) {
        const urlCur = `${environment.baseUrl}/wks/business/documents/?` + 'entity=' + _entity + '&ownerId=' + _ownerId;
        // "equiptype", "docbrand", "docmodel"
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    saveWksDoc(_docCard, actionType) {
        const urlCur = `${environment.baseUrl}/wks/equipment/savedocs/?` + 'action=' + actionType;
        const body = JSON.stringify(_docCard);
        return this.httpClient
            .put(urlCur, body, {
            headers: this.httpHeaders,
            observe: 'response',
        }).pipe(catchError(this.handleErrorObservable));
    }
    downloadDoc(_path, _mimeApp) {
        _path = CommonMethods.cleanURLString(_path);
        const urlCur = `${environment.baseUrl}/wks/equipment/document/download/?` + 'path=' + _path;
        const localHttpHeaders = this.httpHeaders;
        localHttpHeaders.set('Accept', _mimeApp);
        return this.httpClient.get(urlCur, {
            headers: localHttpHeaders,
            observe: 'response',
            responseType: 'blob',
        }).pipe(map((result) => {
            return result;
        }));
    }
    saveToFileSystem(_data, _filename, _mimeApp) {
        const blob = new Blob([_data], { type: _mimeApp });
        saveAs(blob, _filename);
    }
    getPdfDoc(_path) {
        _path = CommonMethods.cleanURLString(_path);
        const urlCur = `${environment.baseUrl}/wks/equipment/document/download/?` + 'path=' + _path;
        const localHttpHeaders = this.httpHeaders;
        localHttpHeaders.set('Accept', 'application/pdf');
        return this.httpClient.post(urlCur, undefined, {
            headers: localHttpHeaders,
            observe: 'response',
            responseType: 'blob',
        }).pipe(map((result) => {
            return result;
        }));
    }
    /*========================================================================= wksContacts --*/
    getContactsList(_entity, _cntAppli, _cntAppliLnk) {
        const urlCur = `${environment.baseUrl}/wks/wkscontacts/get/?` + 'entity=' + _entity + '&cntappli=' + _cntAppli + '&cntapplilnk=' + _cntAppliLnk;
        // "equiptype", "docbrand", "docmodel"
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    /*========================================================================= wksAnchorages --*/
    getWksAnchoragesList() {
        const urlCur = `${environment.baseUrl}/wks/anchorages/getAll`;
        // "equiptype", "docbrand", "docmodel"
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    saveWksAnchorage(_anchorageCard, actionType) {
        const urlCur = `${environment.baseUrl}/wks/anchorages/save/?` + 'action=' + actionType;
        const body = JSON.stringify(_anchorageCard);
        return this.httpClient
            .put(urlCur, body, {
            headers: this.httpHeaders,
            observe: 'response',
        }).pipe(catchError(this.handleErrorObservable));
    }
    /*========================================================================= paramCustoms --*/
    saveParamCustoms(_paramCustom, actionType) {
        const urlCur = `${environment.baseUrl}/wks/jobs/paramcustoms/save/?` + 'action=' + actionType;
        const body = JSON.stringify(_paramCustom);
        return this.httpClient
            .put(urlCur, body, {
            headers: this.httpHeaders,
            observe: 'response',
        }).pipe(catchError(this.handleErrorObservable));
    }
    getParamCustomsEcoZoneList(_ecoZoneCode) {
        const urlCur = `${environment.baseUrl}/wks/jobs/paramcustoms/getEcoZone/?` + 'ecoZoneCode=' + _ecoZoneCode;
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    getParamCustomsHsCode(_ecoZoneCode, _hsCode) {
        const urlCur = `${environment.baseUrl}/wks/jobs/paramcustoms/getHsCode/?` + 'ecoZoneCode=' + _ecoZoneCode +
            '&hsCode=' + _hsCode;
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    /*========================================================================= wksWorksRates --*/
    saveWksWorkRates(_workrateCard, actionType) {
        const urlCur = `${environment.baseUrl}/wks/jobs/paramworksrates/save/?` + 'action=' + actionType;
        const body = JSON.stringify(_workrateCard);
        return this.httpClient
            .put(urlCur, body, {
            headers: this.httpHeaders,
            observe: 'response',
        }).pipe(catchError(this.handleErrorObservable));
    }
    getWksWorkRatesList(_entity, _userlang) {
        const urlCur = `${environment.baseUrl}/wks/jobs/paramworksrates/get/?` + 'entity=' + _entity + '&userlang=' + _userlang;
        // "equiptype", "docbrand", "docmodel"
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    /*========================================================================= paramPartsSupplies --*/
    updatesRatesPartsSupplies(__updateRatesValues, _actionType) {
        const urlCur = `${environment.baseUrl}/wks/jobs/parampartsupplies/updateRates/?` + 'action=' + _actionType;
        const body = JSON.stringify(__updateRatesValues);
        return this.httpClient
            .put(urlCur, body, {
            headers: this.httpHeaders,
            observe: 'response',
        }).pipe(catchError(this.handleErrorObservable));
    }
    saveParamPartSupplie(_partSupplieCard, actionType) {
        const urlCur = `${environment.baseUrl}/wks/jobs/parampartsupplies/save/?` + 'action=' + actionType;
        const body = JSON.stringify(_partSupplieCard);
        return this.httpClient
            .put(urlCur, body, {
            headers: this.httpHeaders,
            observe: 'response',
        }).pipe(catchError(this.handleErrorObservable));
    }
    getParamPartSupplieList(_entity, _brandName, _paramType, _userlang, _brandRef) {
        // _brandRef = CommonMethods.cleanURLString(_brandRef);
        if (_brandRef.includes('|')) {
            // _brandRef.replace('|', ',,');
            // (/\\/g, '');
            // _brandRef.replace(/\\|/g, ',,');
            const brandRefOr = _brandRef.split('|');
            let iLoop = 0;
            for (const brandOr of brandRefOr) {
                if (iLoop === 0) {
                    _brandRef = brandOr;
                }
                else {
                    _brandRef += ',,' + brandOr;
                }
                iLoop++;
            }
        }
        if (_brandRef.includes('+')) {
            // _brandRef.replace('|', ',,');
            // (/\\/g, '');
            // _brandRef.replace(/\\|/g, ',,');
            const brandRefOr = _brandRef.split('+');
            let iLoop = 0;
            for (const brandOr of brandRefOr) {
                if (iLoop === 0) {
                    _brandRef = brandOr;
                }
                else {
                    _brandRef += ';' + brandOr;
                }
                iLoop++;
            }
        }
        const urlCur = `${environment.baseUrl}/wks/jobs/parampartsupplies/getPartSupplies/?` + 'entity=' + _entity
            + '&brandName=' + _brandName
            + '&paramType=' + _paramType
            + '&brandRef=' + _brandRef
            + '&userlang=' + _userlang;
        // "equiptype", "docbrand", "docmodel"
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    verifyStockParts(partsRequirement, _stdEntity, _workId, _userLang) {
        const urlCur = `${environment.baseUrl}/wks/jobs/wksworks/verifyStockParts/?` + 'entity=' + _stdEntity + '&workId=' + _workId + '&userlang=' + _userLang;
        const body = JSON.stringify(partsRequirement);
        return this.httpClient
            .post(urlCur, body, {
            headers: this.httpHeaders,
            observe: 'response',
        }).pipe(catchError(this.handleErrorObservable));
    }
    /*========================================================================= paramactionsref --*/
    saveParamActionsRef(_paramActionsRefCard, actionType) {
        const urlCur = `${environment.baseUrl}/wks/jobs/paramactionsref/save/?` + 'action=' + actionType;
        const body = JSON.stringify(_paramActionsRefCard);
        return this.httpClient
            .put(urlCur, body, {
            headers: this.httpHeaders,
            observe: 'response',
        }).pipe(catchError(this.handleErrorObservable));
    }
    getParamActionsRefList(_entity, _userlang) {
        const urlCur = `${environment.baseUrl}/wks/jobs/paramactionsref/get/?` + 'entity=' + _entity + '&userlang=' + _userlang;
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    getParamActionsRefByTypeList(_entity, _equipType, _userlang) {
        _equipType = CommonMethods.cleanURLString(_equipType);
        const urlCur = `${environment.baseUrl}/wks/jobs/paramactionsref/getByType/?` + 'entity=' + _entity + '&equipType=' + _equipType + '&userlang=' + _userlang;
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    /*========================================================================= ParamTasksref --*/
    saveParamTasksref(_paramTasksrefCard, actionType) {
        const urlCur = `${environment.baseUrl}/wks/jobs/wkstasks/save/?` + 'action=' + actionType;
        const body = JSON.stringify(_paramTasksrefCard);
        return this.httpClient
            .put(urlCur, body, {
            headers: this.httpHeaders,
            observe: 'response',
        }).pipe(catchError(this.handleErrorObservable));
    }
    getParamTasksrefByTypeList(_entity, _equipType, _userlang, _equipBrand, _equipModel) {
        _equipType = CommonMethods.cleanURLString(_equipType);
        const urlCur = `${environment.baseUrl}/wks/jobs/wkstasks/getByType/?` + 'entity=' + _entity +
            '&equipType=' + _equipType + '&userlang=' + _userlang +
            '&equipBrand=' + _equipBrand + '&equipModel=' + _equipModel;
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    getTaskRefList(_entity, _userlang, _taskList) {
        //  _equipType = CommonMethods.cleanURLString(_equipType);
        const urlCur = `${environment.baseUrl}/wks/jobs/wkstasks/getTaskRefList/?` + 'entity=' + _entity +
            '&userlang=' + _userlang +
            '&taskList=' + _taskList;
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    getParamTasksrefList(_entity, _userlang) {
        const urlCur = `${environment.baseUrl}/wks/jobs/wkstasks/get/?` + 'entity=' + _entity + '&userlang=' + _userlang;
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    getParamTasksrefChildren(_entity, _userlang, _taskrefparent) {
        const urlCur = `${environment.baseUrl}/wks/jobs/wkstasks/getchildren/?` + 'entity=' + _entity + '&userlang=' + _userlang + '&taskrefparent=' + _taskrefparent;
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    getParamTasksref(_entity, _userlang, _taskrefId) {
        const urlCur = `${environment.baseUrl}/wks/jobs/wkstasks/getTaskRef/?` + 'entity=' + _entity + '&userlang=' + _userlang + '&taskrefid=' + _taskrefId;
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    //   // /wks/jobs/wkstasks/stockUnitPrices/
    /*
        const body = JSON.stringify(_paramTasksrefCard);
  
      return this.httpClient
        .put<WksTasksModel>(urlCur, body, {
          headers: this.httpHeaders,
          observe: 'response',
        }).pipe(
          catchError(this.handleErrorObservable)
        );
    */
    getUnitPricesStock(_entity, suppliesList) {
        const urlCur = `${environment.baseUrl}/wks/jobs/wkstasks/stockUnitPrices/?` + 'entity=' + _entity;
        const body = JSON.stringify(suppliesList);
        return this.httpClient
            .post(urlCur, body, {
            headers: this.httpHeaders,
            observe: 'response',
        }).pipe(catchError(this.handleErrorObservable));
    }
    /*==================================================================== wks works --*/
    getWorksHistory(_entity, _userlang, _equipRequest) {
        const urlCur = `${environment.baseUrl}/wks/jobs/wksworks/getHistory/?` + 'userlang=' + _userlang;
        const body = JSON.stringify(_equipRequest);
        return this.httpClient
            .post(urlCur, body, {
            headers: this.httpHeaders,
            observe: 'response',
        }).pipe(catchError(this.handleErrorObservable));
    }
    getWorksList(_entity, _userlang) {
        const urlCur = `${environment.baseUrl}/wks/jobs/wksworks/getlist/?` + 'entity=' + _entity + '&userlang=' + _userlang;
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    getWorksByStatut(_entity, _userlang, _statut) {
        const urlCur = `${environment.baseUrl}/wks/jobs/wksworks/bystatut/?` + 'entity=' + _entity + '&statut=' + _statut + '&userlang=' + _userlang;
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    getWorksByCriticality(_entity, _userlang, _criticality) {
        const urlCur = `${environment.baseUrl}/wks/jobs/wksworks/bystatut/?` + 'entity=' + _entity + '&userlang=' + _userlang + '&criticality=' + _criticality;
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    getWorksById(_entity, _userlang, _workId) {
        const urlCur = `${environment.baseUrl}/wks/jobs/wksworks/byid/?` + 'entity=' + _entity + '&userlang=' + _userlang + '&id=' + _workId;
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    getWorkId() {
        const urlCur = `${environment.baseUrl}/wks/jobs/wksworks/getworkid/`;
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            console.log(err.status);
            return observableThrowError(err);
        }));
    }
    getOthersWorks(_equiId, _workId, _userlang, _equipPartOf) {
        const urlCur = `${environment.baseUrl}/wks/jobs/wksworks/othersworks/?` + 'equipid=' + _equiId
            + '&id=' + _workId
            + '&userlang=' + _userlang
            + '&equipPartOf=' + _equipPartOf;
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    generateQuotation(_entity, _userlang, _id) {
        const urlCur = `${environment.baseUrl}/wks/jobs/wksworks/generateQuotation/?` + 'entity=' + _entity + '&userlang=' + _userlang + '&id=' + _id;
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    displayQuotation(_entity, _userlang, _id) {
        const urlCur = `${environment.baseUrl}/wks/jobs/wksworks/getquotation/?` + 'entity=' + _entity + '&userlang=' + _userlang + '&id=' + _id;
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    updateQuotation(_userlang, _docId, _wksWorks, _action) {
        const urlCur = `${environment.baseUrl}/wks/jobs/wksworks/updatequotation/?` + 'userlang=' + _userlang
            + '&docId=' + _docId + '&action=' + _action;
        const body = JSON.stringify(_wksWorks);
        return this.httpClient
            .put(urlCur, body, {
            headers: this.httpHeaders,
            observe: 'response',
        }).pipe(catchError(this.handleErrorObservable));
    }
    getTasksAndDocsWork(_wksWork, entity, userlang) {
        const urlCur = `${environment.baseUrl}/wks/jobs/wksworks/getTasksAndDocsWork/?` + 'entity=' + entity + '&userlang=' + userlang;
        // const body = JSON.stringify(_wksWork);
        const body = JSON.parse(JSON.stringify(_wksWork));
        return this.httpClient
            .post(urlCur, body, {
            headers: this.httpHeaders,
            observe: 'response',
        }).pipe(catchError(this.handleErrorObservable));
    }
    saveWksWorks(_wksWorksModel, actionType) {
        const urlCur = `${environment.baseUrl}/wks/jobs/wksworks/save/?` + 'action=' + actionType;
        const body = JSON.stringify(_wksWorksModel);
        return this.httpClient
            .put(urlCur, body, {
            headers: this.httpHeaders,
            observe: 'response',
        }).pipe(catchError(this.handleErrorObservable));
    }
    lockWork(wksWorksCur, entity, userlang) {
        const urlCur = `${environment.baseUrl}/wks/jobs/wksworks/lockWork/?` + 'entity=' + entity + '&userlang=' + userlang;
        return this.httpClient.post(urlCur, wksWorksCur, {
            headers: this.httpHeaders,
            observe: 'response',
        });
    }
    removeWksWorksDoc(_wksWorksDocs) {
        const urlCur = `${environment.baseUrl}/wks/jobs/wksworks/document/remove/`;
        const body = JSON.stringify(_wksWorksDocs);
        return this.httpClient
            .put(urlCur, body, {
            headers: this.httpHeaders,
            observe: 'response',
        }).pipe(catchError(this.handleErrorObservable));
    }
    downloadWorkImg(_path, _mimeApp, _imageType) {
        _path = CommonMethods.cleanURLString(_path);
        const urlCur = `${environment.baseUrl}/wks/jobs/wksworks/document/download/?` + 'path=' + _path + '&type=' + _imageType;
        const localHttpHeaders = this.httpHeaders;
        localHttpHeaders.set('Accept', _mimeApp);
        return this.httpClient.get(urlCur, {
            headers: localHttpHeaders,
            observe: 'response',
            responseType: 'blob',
        }).pipe(map((result) => {
            return result;
        }));
    }
    downloadWorkPdf(_path) {
        _path = CommonMethods.cleanURLString(_path);
        const urlCur = `${environment.baseUrl}/wks/jobs/wksworks/document/download/?` + 'path=' + _path + '&type=pdf';
        const localHttpHeaders = this.httpHeaders;
        localHttpHeaders.set('Accept', 'application/pdf');
        return this.httpClient.get(urlCur, {
            headers: localHttpHeaders,
            observe: 'response',
            responseType: 'blob',
        }).pipe(map((result) => {
            return result;
        }));
    }
    getWksAppointments(_entity, _statut, _timeZone) {
        let urlCur = `${environment.baseUrl}/wks/appointments/getAllAppointments/?` + 'entity=' + _entity + '&timezone=' + _timeZone;
        if (_statut !== undefined && _statut !== null) {
            urlCur = `${environment.baseUrl}/wks/appointments/getAppointmentsByStatut/?` + 'entity=' + _entity + '&statut=' + _statut + '&timezone=' + _timeZone;
        }
        return this.httpClient.post(urlCur, undefined, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    getAppointmentsWaitingDate(_entity, _statut, _timeZone) {
        const urlCur = `${environment.baseUrl}/wks/appointments/getAppointmentsWaitingDate/?` + 'entity=' + _entity + '&statut=' + _statut + '&timezone=' + _timeZone;
        return this.httpClient.post(urlCur, undefined, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    getAppointmentUUID(_entity, _uuid, _timeZone) {
        const urlCur = `${environment.baseUrl}/wks/appointments/getAppointmentsByUUID/?` + 'entity=' + _entity + '&uuid=' + _uuid + '&timezone=' + _timeZone;
        return this.httpClient.post(urlCur, undefined, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    getWksEntityParams(_entity) {
        const urlService = `${environment.baseUrl}/wks/appointments/getEntityParams/?` + 'entity=' + _entity;
        const localHttpHeaders = this.httpHeaders;
        return this.httpClient
            .post(urlService, undefined, {
            headers: localHttpHeaders,
            observe: 'response'
        });
    }
    saveWksAppointment(_appointmentCur, actionType, actorEmail) {
        const urlCur = `${environment.baseUrl}/wks/appointments/wksAppointment/save/?` + 'action=' + actionType + '&actorEmail=' + actorEmail;
        const body = JSON.stringify(_appointmentCur);
        return this.httpClient
            .put(urlCur, body, {
            headers: this.httpHeaders,
            observe: 'response',
        }).pipe(catchError(this.handleErrorObservable));
    }
    saveWksAppointmentTracking(_appointmentTracking, actionType) {
        const urlCur = `${environment.baseUrl}/wks/appointments/wksTrackingAppointment/save/?` + 'action=' + actionType;
        const body = JSON.stringify(_appointmentTracking);
        return this.httpClient
            .put(urlCur, body, {
            headers: this.httpHeaders,
            observe: 'response',
        }).pipe(catchError(this.handleErrorObservable));
    }
    saveWksAccounting(_accountingCur, actionType) {
        const urlCur = `${environment.baseUrl}/wks/jobs/wksAccounting/save/?` + 'action=' + actionType;
        const body = JSON.stringify(_accountingCur);
        return this.httpClient
            .put(urlCur, body, {
            headers: this.httpHeaders,
            observe: 'response',
        }).pipe(catchError(this.handleErrorObservable));
    }
    getAccountingTransferlist(_entity) {
        const urlCur = `${environment.baseUrl}/wks/jobs/wksAccounting/getTransferlist/?` + 'entity=' + _entity;
        return this.httpClient.post(urlCur, undefined, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    getAccountingEntitylist(_entity, customerId) {
        const urlCur = `${environment.baseUrl}/wks/jobs/wksAccounting/getEntitylist/?` + 'entity=' + _entity + '&customerId=' + customerId;
        return this.httpClient.post(urlCur, undefined, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    getAccountingWorklist(_entity, workId) {
        const urlCur = `${environment.baseUrl}/wks/jobs/wksAccounting/getWorklist/?` + 'entity=' + _entity + '&workId=' + workId;
        return this.httpClient.post(urlCur, undefined, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    getAccountingWorkBalance(_entity, workId) {
        const urlCur = `${environment.baseUrl}/wks/jobs/wksworks/balance/?` + 'entity=' + _entity + '&workId=' + workId;
        return this.httpClient.post(urlCur, undefined, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    getAccountingCustomerBalance(_entity, customerId) {
        const urlCur = `${environment.baseUrl}/wks/business/balance/?` + 'entity=' + _entity + '&customerId=' + customerId;
        return this.httpClient.post(urlCur, undefined, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    getAccountingCustomerlist(_entity) {
        const urlCur = `${environment.baseUrl}/wks/jobs/wksAccounting/getCustomerlist/?` + 'entity=' + _entity;
        return this.httpClient.post(urlCur, undefined, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    /*========================================================================= Menu --*/
    getWksMenu() {
        const urlCur = `${environment.baseUrl}/wks/menu/`;
        // this.retrieveUser();
        return this.httpClient.get(urlCur, {
            headers: this.httpHeaders,
            observe: 'response'
        });
    }
    setWksMenuButton(_menuItems) {
        this.wksMenuItems = _menuItems;
        this.wksMenuButtonEvents.next(_menuItems);
    }
    setWksMenuItems(_menuItems) {
        this.wksMenuItems = _menuItems;
        this.wksMenuItemsEvents.next(_menuItems);
    }
    getWksMenuItems() {
        return this.wksMenuItems;
    }
    handleErrorObservable(error) {
        // console.error(error.message || error);
        return observableThrowError(error.message || error);
    }
    downloadImgFile(_path, _imgFile) {
        const fileExtension = _imgFile.split('.').pop();
        const mimeApp = this.getContentMime(fileExtension);
        return new Promise((resolve, reject) => {
            this.downloadWorkImg(_path, mimeApp, fileExtension)
                .pipe(takeUntil(this.onDestroy))
                .subscribe((retMes) => {
                const response = retMes.body;
                const blob = new Blob([response], { type: mimeApp, });
                resolve(blob);
            }, err => {
                reject(err);
            });
        });
    }
}
WksCentralService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WksCentralService_Factory() { return new WksCentralService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ClipboardService), i0.ɵɵinject(i3.RessourcesService), i0.ɵɵinject(i4.TranslateService)); }, token: WksCentralService, providedIn: "root" });
