import { Component, OnInit, OnDestroy, Input, TemplateRef, ViewChild, ElementRef, AfterViewInit, SimpleChanges, OnChanges, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Subject } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { takeUntil } from 'rxjs/operators';
import { GlobalEventsManagerService } from '../../../../../../job/common/services/global-events-manager.service';
import { UserService } from '../../../../../../services/user.service';
import { TranslateService } from '../../../../../../services/translate.service';
import { RessourcesService } from '../../../../../common/services/ressources.service';
import { WksCentralService } from '../../../../services/wks-central.service';
import { WksWorksService } from '../../../../services/wks-works.service';
import { ParamActionsrefModel, ParamLabelsI18n, TasksActionsModel, ParamWorksRatesModel, TasksActionsContentModel, 
      ParentTask, EventPartsSupplies, ParamPartsSuppliesModel, EventStatutCur, IhmOptions } from '../../../../models/wks-param.model';

import { WksEntityModel } from '../../../../models/wks-entity.model';
import { MeasurementList, DataUpstream } from '../../../../models/wks-common.model';
import { WksBusinessModel } from '../../../../models/wks-business.model';
import { LabelValue } from '../../../../../../models/data.model';

@Component({
  selector: 'mdi-taskref-actions',
  templateUrl: './taskref-actions.component.html',
  styleUrls: ['./taskref-actions.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TaskrefActionsComponent implements OnInit, OnDestroy, AfterViewInit  {

  @Input() optionItem: any;
  @Input() actionType: any;
  @Input() parentParam: any;
  @Input() wksEntity: WksEntityModel;
  @Input() parentTask: ParentTask;
  @Input() rowTask: number;
  @Input() ihmOptions: IhmOptions;
  @Output() dataOut = new EventEmitter<EventStatutCur>();

  @ViewChild('filterActions', {static: false}) filterActions: ElementRef;
  @ViewChild('actionModal', {read: TemplateRef, static: false}) actionModal: TemplateRef<any>;
  @ViewChild('searchModal', {read: TemplateRef, static: false}) searchModal: TemplateRef<any>;

  private readonly onDestroy = new Subject<void>();

  actionsReferenced: TasksActionsModel[];
  actionsSelected: TasksActionsModel[];
  suppliesList: TasksActionsModel[];
  supplieEmpty: TasksActionsModel;
  cacheActionsList: TasksActionsModel[];
  toSelected = false;
  toReferenced = false;
  isAlreadyQuoted = false;
  isStockManagment = false;

  validOK = false;
  entityCur: string;
  isLoaded: boolean;
  listActionsCur: ParamActionsrefModel[];
  workCodeList: LabelValue[];
  unitsQuantity: LabelValue[];
  taskActionsList: LabelValue[];
  ownerCur: WksBusinessModel;
  worksRates: ParamWorksRatesModel[];
  scaleName: string;
  bsnDiscount: number;
  bsnCoef: number;

  modalRef: BsModalRef;
  configModal = {
    class: 'modal-dialog-centered modal-xl',
    backdrop: true,
    ignoreBackdropClick: true,
    animated: false,
  };
  dataUpstream: DataUpstream;
  intFormat: string ;
  numberFormat: { 
    locale: string;
    minDecimal: number;
    maxDecimal: number;
    currency: string;
  };
  tasksActionsContentCache: TasksActionsContentModel;
  isReadOnly: boolean;

  constructor(private _translate: TranslateService, 
              private _userService: UserService, 
              private _ressourcesService: RessourcesService,
              private _wksCentralService: WksCentralService,
              private _globalEventsManagerService: GlobalEventsManagerService,
              private modalService: BsModalService,
              private _wksWorksService: WksWorksService) { }

  ngOnInit() {
    this.initData();
  }
  /*
  ngOnChanges(changes: SimpleChanges) { 
    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'optionItem': {
            this.optionItem = changes['optionItem'].currentValue;
            break;
          }
          case 'actionType': {
            this.actionType = changes['actionType'].currentValue;
            break;
          }
 // tasksActionsParentContent
          case 'parentParam': {
            this.parentParam = changes['parentParam'].currentValue;
            break;
          }
          case 'wksEntity': {
            this.wksEntity = changes['wksEntity'].currentValue;
            break;
          }
          // parentTask
          case 'parentTask': {
            this.parentTask = changes['parentTask'].currentValue;
            break;
          }
        } // end switch
      } // end if
    } // end loop
    this.initData();
  }*/
  ngOnDestroy() {
    this.onDestroy.next();

  }
  ngAfterViewInit() {
    const nbHiden = this.actionsReferenced.length;
    const nbDisplay = this.actionsSelected.length;
    // const heigtRow = 35;
    let heigthCur = 35 * (nbHiden + nbDisplay);
    if ( heigthCur > 460) {
      heigthCur = 460;
    }
    if (this.optionItem === 'works') {
      document.getElementById('actionsSelected').style.height = heigthCur.toString() + 'px';
      document.getElementById('actionsReferenced').style.height = heigthCur.toString() + 'px';
      document.getElementById('columnCentral').style.height = heigthCur.toString() + 'px';
    }
  }
  initData() {
    if (this._userService.getUserLogged() === undefined ) { return; }

    this.initAllBooleans();
    this.initAllParams();
    this.initAllSubscribes();
    this.settingFormats();
    
  }
  initAllBooleans() {
    this.isLoaded = false;  
    this.toReferenced = false;
    this.toSelected = false;
    this.isStockManagment = false;
  }
  initAllParams()  {
    this.actionsReferenced = [];
    this.actionsSelected = [];
    this.suppliesList = [];
    this.cacheActionsList = [];
    this.entityCur = this._userService.getUserLogged().entity;   
    this.supplieEmpty = {
      ranking: 0,
      brandRef: '',
      actionrefId: '',
      actionrefLabel: '',
      billingUnit: '',
      quantity: null,
      unitPrice: 0.0,
      extPrice: 0,
      comment: '',
      quoted: null,
      invoiced: null,
      status: null,
      available: false,
    };
    if (this.ihmOptions) {
      this.isReadOnly = this.ihmOptions.readOnly;
    }

    const countryEntity  = this._ressourcesService.getCountry(this._userService.getUserLogged().entitycountry);
    const isMetric = (countryEntity.measurementUnit === 'METRIC');
    const jsonUnits = this._wksCentralService.getUnitsQuantity() as MeasurementList[];
    let measurementLocal = 'METRIC';
    if (!isMetric) {
      measurementLocal = 'NON_METRIC';
    }
    this.isStockManagment = this._wksCentralService.getApiStockManagement() !== undefined ? true : false;
    // tires
    this.unitsQuantity  = [];
    for (const measurLocal of jsonUnits) {
      if (measurLocal.measurement === measurementLocal) {
        const arrayUnits = [...measurLocal.list];
        for (const unitName of arrayUnits) {

          this.unitsQuantity.push({
            label: this._translate.getTranslate(unitName),
            value: unitName
          });
        }
        this.unitsQuantity.splice(0, 0, {
          label: '',
          value: ''
        });
        break;
      }
    }
    this.taskActionsList  = [];
    const jsonTaskActions = this._wksCentralService.getTaskActions() as string[];
    for (const actionTask of jsonTaskActions) {
      this.taskActionsList.push({
        label: this._translate.getTranslate(actionTask),
        value: actionTask
      });
    }

    if (this.actionType === 'withprice') {
      this.ownerCur = this._wksWorksService.getOwner();
      let accounting: any;
      if (this.ownerCur.bsnAccounting.bsnAccount === undefined) {
        accounting = JSON.parse(this.ownerCur.bsnAccounting);
      } else {
        accounting = this.ownerCur.bsnAccounting;
      }

      this.scaleName = (accounting.scaleName ? accounting.scaleName : 'PUBLIC');

      this.bsnDiscount = (accounting.bsnDiscount ? accounting.bsnDiscount : 0.0);
      this.bsnCoef = (accounting.bsnCoef ? accounting.bsnCoef : 1.0);
    }
    this.getParamActionsRefList()
    .then (actionsRead  => {
      // let rankCur = 0;
      this.initReferencedList();
      this.loadWorksType()
      .then(data => {
        this.displayActions();
        this.isLoaded = true;
      });
    })
    .catch(error  => {
      console.log(error);
      return;
    });
    const nbHiden = this.actionsReferenced.length;
    const nbDisplay = this.actionsSelected.length;
    // const heigtRow = 35;
    let heigthCur = 35 * (nbHiden + nbDisplay);
    if ( heigthCur > 460) {
      heigthCur = 460;
    } 
  }
  initAllSubscribes() {
/*
    this._wksCentralService.onSearchParts
    .pipe(takeUntil(this.onDestroy))
    .subscribe((event: EventPartsSupplies) => {
    
      if (event.statut === 'cancel') {
        this.closeModal();
      } else if (event.partsSupplies.length > 0) {
        this.closeModal();
        this.addListSupplies(event.partsSupplies);
      }
      
      // this.getWksEquipmentList();
    });
    */
    this._globalEventsManagerService.synchroEvents
    .pipe(takeUntil(this.onDestroy))
    .subscribe( event => {
      if (event === 'closeActionModal') {
        this.closeModal();
        // this.loadDocs();
      } else if (event === 'closeRegisterActionModal') {
        this.closeModal();
        this.getParamActionsRefList()
        .then (actionsRead  => {
          // let rankCur = 0;
          this.initReferencedList();
        })
        .catch(error  => {
          console.log(error);
          return;
        });
      } else if (event) {
        const typeEvent = typeof event;
        const eventString = String(event);
        // console.log(typeEvent);
      }
    });
  }



  settingFormats() {
    const otherData = JSON.parse(this.wksEntity.otherData);
    this.intFormat = otherData.internationnalFormat;
    this.numberFormat = { 
      locale: this._ressourcesService.getFormat(this.intFormat, 'numbers', 'locale'),
      minDecimal: Number.parseInt(this._ressourcesService.getFormat(this.intFormat, 'numbers', 'minimumFractionDigits'), 10),
      maxDecimal: Number.parseInt(this._ressourcesService.getFormat(this.intFormat, 'numbers', 'maximumFractionDigits'), 10),
      currency:  this._ressourcesService.getFormat(this.intFormat, 'numbers', 'currency'),
    };
    
  }
  initReferencedList() {
    this.cacheActionsList = [];
    this.actionsReferenced = [];
    for (const actionReading of this.listActionsCur)  {
      this.actionsReferenced.push({
        brandRef: actionReading.brandRef,
        actionrefId: actionReading.actionrefId,
        actionrefLabel: this.translateActionRef(actionReading),
        billingUnit: this._translate.getTranslate(actionReading.billingUnit.trim()),
        workCode: actionReading.workCode,
        selected: false,
      });
      this.cacheActionsList.push({
        brandRef: actionReading.brandRef,
        actionrefId: actionReading.actionrefId,
        actionrefLabel: this.translateActionRef(actionReading),
        billingUnit: this._translate.getTranslate(actionReading.billingUnit.trim()),
        workCode: actionReading.workCode,
        selected: false,
      });

    }
    if (this.actionsReferenced.length > 0) {
      this.actionsReferenced.sort(function(obj1, obj2) {
        return obj1.actionrefLabel > obj2.actionrefLabel ? 1 : -1;
      });
      this.toSelected = true;
      // this.cacheActionsList = [...this.actionsReferenced];
    } else {
      // this.toReferenced = true;
      
    }
    if (this.actionsSelected.length === 0) {
      // this.toSelected = true;
    } else {
      this.actionsSelected.sort(function(obj1, obj2) {
        return obj1.ranking > obj2.ranking ? 1 : -1;
      });
      this.toReferenced = true;
    }
  }
  getParamActionsRefList() {
    return new Promise((resolve, reject) => {
      this._wksCentralService.getParamActionsRefList(this._userService.getUserLogged().entity, this._userService.getUserLogged().userlang)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          const actionsRead = data.body as ParamActionsrefModel[];
          this.listActionsCur = [...this.translateActionsRef(actionsRead)];
          resolve(actionsRead);
        },
        err => {
          console.log(err);
          reject(err.status);
        }
      );
    });
  }
  loadWorksType() {
    let dataReturn: ParamWorksRatesModel[];
    this.workCodeList = [];
    return new Promise((resolve, reject) => {
      this._wksCentralService.getWksWorkRatesList(this._userService.getUserLogged().entity, this._userService.getUserLogged().userlang)
     .pipe(takeUntil(this.onDestroy))
     .subscribe(
       data => {
          dataReturn = data.body;
          this.worksRates = dataReturn;
          for (const workCur of dataReturn) {
            const workLabelCur: LabelValue = {
              label: workCur.workLabel,
              value: workCur.workCode
            };
            this.workCodeList.push(workLabelCur);
          }
          this.workCodeList.sort((obj1: LabelValue, obj2: LabelValue) => {
            return obj1.label > obj2.label ? 1 : -1;
          });
          this.workCodeList.splice(0, 1, {
            label: this._translate.getTranslate('all jobs type'),
            value: 'all jobs type',
          });
          resolve(dataReturn) ;
        }, err => {
          console.log(err);
          reject(err.status);
        }
      );
    });
  }
  onChangeWork($event: any) {
    this.actionsReferenced = [];
    this.cacheActionsList = [];
       
    for (const actionReading of this.listActionsCur)  {
      if ((actionReading.workCode === $event) || ('all jobs type' === $event)) {
        this.actionsReferenced.push({
          brandRef: actionReading.brandRef,
          actionrefId: actionReading.actionrefId,
          actionrefLabel: this.translateActionRef(actionReading),
          billingUnit: this._translate.getTranslate(actionReading.billingUnit.trim()),
          workCode: actionReading.workCode,
          selected: false,
        });
        this.cacheActionsList.push({
          brandRef: actionReading.brandRef,
          actionrefId: actionReading.actionrefId,
          actionrefLabel: this.translateActionRef(actionReading),
          billingUnit: this._translate.getTranslate(actionReading.billingUnit.trim()),
          workCode: actionReading.workCode,
          selected: false,
        });
      }
    }
    if (this.actionsReferenced.length > 0) {
      this.actionsReferenced.sort(function(obj1, obj2) {
        return obj1.actionrefLabel > obj2.actionrefLabel ? 1 : -1;
      });
      if (this.actionsSelected.length > 0 ) {      
        for (const actionSel of this.actionsSelected) {
          let numRow = 0;
          for ( const actionRef of this.actionsReferenced) {
            if (actionRef.actionrefId === actionSel.actionrefId) {
              this.actionsReferenced.splice(numRow, 1);
              const workRate = this.getWorkRate(actionRef.workCode);
              actionSel.unitPrice = workRate;
              if ((actionSel.quantity !== undefined) && ( Number(actionSel.quantity) > 0) ) {
                actionSel.extPrice = actionSel.unitPrice *  Number(actionSel.quantity);
              } else {
                actionSel.extPrice = 0.0;
              }
              break;
            }
            numRow++;
          }
        }
      }
      this.toSelected = true;
    } else {
      // this.initReferencedList();
    }
  }
  translateActionsRef(actionsCur: ParamActionsrefModel[]): ParamActionsrefModel[] {
    const localArray:  ParamActionsrefModel[] = [];
    for (const localData of actionsCur ) {
      let langLabels: ParamLabelsI18n[];
      
      langLabels = localData.langLabels;
      if (langLabels !== null ) {
        for (const langLabel of langLabels) {
          if (langLabel.labelLang === this._translate.getCurrentLang()) {
            localData.actionrefLabel =  langLabel.labelText;
            break;
          }
        }
      }
      localArray.push(localData);
    }
    return localArray;
  }
  translateActionSelected(tasksAction: TasksActionsModel): string {
    let actionLabel = '';
    for (const actionCur of this.listActionsCur) {
      if (actionCur.actionrefId === tasksAction.actionrefId) {
        actionLabel = this.translateActionRef(actionCur);
        break;
      }
    }
    return actionLabel;
  }
  translateActionRef(actionsCur: ParamActionsrefModel): string {
    const langLabels = actionsCur.langLabels;
    let localLabel = '';
    if (langLabels !== null ) {
      for (const langLabel of langLabels) {
        if (langLabel.labelLang === this._translate.getCurrentLang()) {
          localLabel =  langLabel.labelText;
          break;
        }
      }
    }
    return localLabel;
}
  onKeyFilterActions($event: any) {
    // console.log(value);
    if ($event === '' ) {
      this.actionsReferenced = [...this.cacheActionsList];
      return;
    }
    let valueFinal = $event.target.value;
    if ( $event.keyCode === 32) {
      valueFinal += ' ';
    }
    this.actionsReferenced = this.searchActions(valueFinal);
    this.filterActions.nativeElement.value = valueFinal;
  }
  searchActions(value: string) { 
    const filter = value.toLowerCase();
    return this.actionsReferenced.filter(actionCur => actionCur.actionrefLabel.toLowerCase().includes(filter));
  }
  getWorkRate(_workCode: string): number {
    let workRate = 0.0;
    let rateFound = false;
    if (this.actionType === 'withprice') {
      for (const workCur of this.worksRates) {
        if (workCur.workCode === _workCode) {
          const pricingTmp = JSON.parse(workCur.workPricing) as any[];
          for (const pricingCur of pricingTmp) {
            if (pricingCur.scaleName === this.scaleName) {
              workRate = pricingCur.scaleRate;
              rateFound = true;
              break;
            }
          }
        }
        if (rateFound) {
          break;
        }
      }
      if (workRate > 0.0) {
        if (this.bsnDiscount > 0.0) {
          workRate = workRate - ((workRate * this.bsnDiscount) / 100);
        }
        if (this.bsnCoef > 0) {
          workRate = workRate * this.bsnCoef;
        }
      }
      return workRate;
    } 
  }
  referencedToSelected(arg: string) {

    // this.actionsSelected = [];
    let rankCur = 0;
    if (this.actionsSelected.length > 0) {
      rankCur = this.actionsSelected.length;
    }
    if (arg === 'checked') {
      let idxSelected = 0;
      const cacheReferenced = [...this.actionsReferenced];
      for (const actionReading of this.actionsReferenced)  {
        
        if (actionReading.selected) {
          rankCur++;
          actionReading.ranking = rankCur;
          actionReading.unitPrice =  this.getWorkRate(actionReading.workCode);
          this.actionsSelected.push(actionReading);
          
          cacheReferenced.splice(idxSelected, 1);
          idxSelected--;
        }
        idxSelected++;
      }
      this.actionsReferenced = [];
      this.actionsReferenced = [...cacheReferenced];
      if (this.actionsReferenced.length === 0) {
        this.toSelected = false;
      }
      if (this.actionsSelected.length > 0) {
        this.toReferenced = true;
      }
      this.registerActions('works', false);
      return;
    }
 

    for (const actionReading of this.actionsReferenced)  {
      rankCur++;
      actionReading.ranking = rankCur;
      
      this.actionsSelected.push(actionReading);
    }
    this.actionsReferenced = [];
    this.toSelected = false;
    this.toReferenced = true;
    this.registerActions('works', false);
  }
  selectedToReferenced() {
    this.actionsReferenced = [];
    this.actionsSelected = [];
    this.initReferencedList();
    this.toSelected = true;
    this.toReferenced = false;
    this.registerActions('works', false);
  }
 drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer !== event.container) {
      transferArrayItem(event.previousContainer.data, event.container.data, 
        event.previousIndex, event.currentIndex);
    } else {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

    } 
    let listActions: any[];
    if (event.container.id === 'actionsSelected') {
      this.actionsSelected[event.currentIndex].ranking = event.currentIndex + 1;
      this.actionsSelected[event.currentIndex].selected = true;
      listActions = event.container.data ;
      this.registerActions('works', false);
    } else {
      this.actionsReferenced[event.currentIndex].selected = false;
      listActions = event.previousContainer.data ;
      this.registerActions('works', false);
    }
    // ranking calcul
    let idx = -1;
    for (const curRow of listActions) {

      idx++;
      for (const actionsList of this.actionsSelected) {
        if (actionsList.quoted === undefined) {
          actionsList.quoted = null;
          actionsList.invoiced = null;
          actionsList.status = null;
        }
        if (curRow.actionrefId === actionsList.actionrefId) {
          actionsList.ranking = idx + 1;
          actionsList.unitPrice =  this.getWorkRate(actionsList.workCode);
        }
      }
    }
    if (this.actionsReferenced.length > 0) {
      this.toSelected = true;
      this.actionsReferenced.sort(function(obj1, obj2) {
        return obj1.actionrefLabel > obj2.actionrefLabel ? 1 : -1;
      });
    } else {
      this.toReferenced = true;
      this.toSelected = false;
    }
    if (this.actionsSelected.length > 0) {
      this.toReferenced = true;
      

    } else {
      this.toReferenced = false;
      this.toSelected = true;
      
    }

  }
  checkChange(fieldChecked: string, inputValue: any, index: number) {
    if (fieldChecked === 'checked') {
      this.toSelected = true;
      this.actionsReferenced[index].selected = inputValue;
    }

  }
  getPartsPrices(): void {
    // console.log(JSON.stringify(this.suppliesList));
    // getUnitPricesStock
    this.getStockPartsPrices()
    .then (actionsRead  => {
      // let rankCur = 0;
    })
    .catch(error  => {
      console.log(error);
      return;
    });
  }
  getStockPartsPrices() {
    return new Promise((resolve, reject) => {
      this._wksCentralService.getUnitPricesStock(this._userService.getUserLogged().entity, this.suppliesList)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          this.suppliesList = data.body as TasksActionsModel[];
          for ( const itemSupplie of this.suppliesList) {
            itemSupplie.extPrice = itemSupplie.unitPrice *  Number(itemSupplie.quantity);
          }

          resolve('OK');
        },
        err => {
          console.log(err);
          reject(err.status);
        }
      );
    });
  }
  addSupplies(index: number) {
    this.openSearchModal();
    /*
    
    this.suppliesList.push(this.supplyEmpty);
    */
  }
  addListSuppliesModal($event: any) {
    this.closeModal();
    if (typeof($event) === 'string') {
      if ($event === 'cancel') {
        return;
      }
    }
    this.addListSupplies($event);
  }
  addListSupplies(partsSupplies: ParamPartsSuppliesModel[]) {
    for (const partSupplieCur of partsSupplies) {
      let isFound = false;
      for (const supplieExists of this.suppliesList) {
        if (supplieExists.partSupplieId === partSupplieCur.id) {
          isFound = true;
          break;
        }
      }
      if (isFound) {
        isFound = false;
        continue;
      }
      const supplieCur = {
        ranking: 0,
        brandRef: partSupplieCur.brandRef,
        partSupplieId: partSupplieCur.id,
        actionrefLabel: partSupplieCur.partsSuppliesLabel,
        billingUnit: partSupplieCur.paramUnit,
        quantity: null,
        unitPrice: 0.0,
        extPrice: 0,
        comment: '',
        quoted: null,
        invoiced: null,
        status: null,
        available: false,
        parentElement: false
      };
      this.suppliesList.push(supplieCur);
      /*
      this.statutCur({
        subject: 'partsSupplies',
        failed: true
      });
      */
    }
    this.registerActions('supplies&parts', false);
  }
  removeSupplies(index: number) { 
    if (this.suppliesList.length === 1) {
      this.suppliesList[index] = this.supplieEmpty;
      // return;
    } else  {
      this.suppliesList.splice(index, 1);
    }
    this.registerActions('supplies&parts', false);
  }

  onChangeUnitQuantity($event: any, index: number) {
 
    this.suppliesList[index].billingUnit = $event.source.value;
    this.registerActions('supplies&parts', false);
  }
  onChangeActionTask($event: any, index: number) {
 
    this.suppliesList[index].actionTask = $event.source.value;
    this.registerActions('supplies&parts', false);
  }
  setChangeValues(_typeInput: string, inputValue: any, index: number) {
    switch (_typeInput) {
      case 'actionrefLabel':
        this.suppliesList[index].actionrefLabel = inputValue;
        break;
      case 'brandRef':
        this.suppliesList[index].brandRef = inputValue;
        break;
      case 'available':
        this.suppliesList[index].available = inputValue;
        break;
    }
    this.registerActions('supplies&parts', false);
  }
  setQuantityValue(_typeInput: string, inputValue: any, index: number) {
    const eventNum = Number.isNaN(inputValue);
    if (_typeInput === 'works') {
      this.actionsSelected[index].quantity = (eventNum ? 0.0 : inputValue);
      this.actionsSelected[index].extPrice = this.actionsSelected[index].unitPrice * Number(this.actionsSelected[index].quantity);
      this.actionsSelected[index].available = true;
      this.registerActions('works', false);
    }
    if (_typeInput === 'supplies') {
      this.suppliesList[index].quantity = (eventNum ? 0.0 : inputValue);
      this.suppliesList[index].extPrice = this.suppliesList[index].unitPrice *  Number(this.suppliesList[index].quantity);
      this.registerActions('supplies&parts', false);
    }
  }
  setUnitPriceValue(_typeInput: string, inputValue: any, index: number) {
    const eventNum = Number.isNaN(inputValue);
    if (_typeInput === 'works') {
      this.actionsSelected[index].unitPrice = (eventNum ? 0.0 : inputValue);
      this.actionsSelected[index].extPrice = this.actionsSelected[index].unitPrice *  Number(this.actionsSelected[index].quantity);
      this.registerActions('works', false);
    }
    if (_typeInput === 'supplies') {
      this.suppliesList[index].unitPrice = (eventNum ? 0.0 : inputValue);
      this.suppliesList[index].extPrice = this.suppliesList[index].unitPrice *  Number(this.suppliesList[index].quantity);
      this.registerActions('supplies&parts', false);
    }
  }
  setExtPriceValue(_typeInput: string, inputValue: any, index: number) {
    const eventNum = Number.isNaN(inputValue);
    if (_typeInput === 'works') {
      this.actionsSelected[index].extPrice = (eventNum ? 0.0 : inputValue);
      this.registerActions('works', false);
    }
    if (_typeInput === 'supplies') {
      this.suppliesList[index].extPrice = (eventNum ? 0.0 : inputValue);
      this.registerActions('supplies&parts', false);
    }
  }
  displayActions() {
    this.isAlreadyQuoted = false;
    if (this.optionItem === 'works') {
      if (this.parentParam !== undefined && this.parentParam.length > 0 ) {
        this.actionsSelected = this.parentParam;
        for (const actionSel of this.actionsSelected) {
          let numRow = 0;
          if ((actionSel.quoted !== undefined) && (actionSel.quoted != null)) {
            this.isAlreadyQuoted = true;
          }
          actionSel.actionrefLabel = this.translateActionSelected(actionSel);
          for ( const actionRef of this.actionsReferenced) {
            if (actionRef.actionrefId === actionSel.actionrefId) {
              this.actionsReferenced.splice(numRow, 1);
              if (actionSel.unitPrice === undefined) {
                actionSel.workCode = actionRef.workCode;
                actionSel.unitPrice =  this.getWorkRate(actionRef.workCode);
              }
              if ((actionSel.quantity !== undefined) && ( Number(actionSel.quantity) > 0) ) {
                let calculatePrice = false;
                if (actionSel.extPrice === null || actionSel.extPrice === undefined ) {
                  calculatePrice = true;
                }
                actionSel.extPrice = actionSel.unitPrice *  Number(actionSel.quantity);
                const eventNum = Number.isNaN(actionSel.extPrice);
                if (calculatePrice && !eventNum ) {
                  this.registerActions('works', true);
                  calculatePrice = false;
                }
              } else {
                actionSel.extPrice = 0.0;
              }
              break;
            }
            numRow++;
          }
        }
        if (this.actionsSelected.length > 0) {
          this.actionsSelected.sort(function(obj1, obj2) {
            return obj1.ranking > obj2.ranking ? 1 : -1;
          });
          this.toReferenced = true;
        }
      } else {
        this.actionsSelected = [];
      }
      this.registerActions('works', false);
    }
    if (this.optionItem === 'supplies&parts') {
      if (this.parentParam !== undefined  && this.parentParam.length > 0 ) {
        this.suppliesList = [];
        for (const supplieTmp of this.parentParam) {
          supplieTmp.taskParent = true;
          this.suppliesList.push(supplieTmp);
        }
      } else {
        this.addSupplies(1);
      }
      this.registerActions('supplies&parts', false);
    }
    
  }
  registerActions(_orig: string, forcedInput: boolean) {

    if (_orig === 'works') {
      for (const taskCur of this.actionsSelected) {
        taskCur.available = true;
      }
    }

    const tasksActionsContent: TasksActionsContentModel = {
      supplies: this.suppliesList,
      works: this.actionsSelected,
      rowTask: (this.rowTask ? this.rowTask : 0)
    };
    if (JSON.stringify(this.tasksActionsContentCache)  !== JSON.stringify(tasksActionsContent) ) {
      this.tasksActionsContentCache = JSON.parse(JSON.stringify(tasksActionsContent));
    } else {
      return;
    }
    if (this.isLoaded || forcedInput) {
      this._wksCentralService.taskWksEvent.emit({
        orig: _orig,
        rowTask: this.rowTask,
        todo: 'changeValuesTask',
        content: tasksActionsContent
      });
    
      this._wksCentralService.taskWksEvent.emit({
        orig: _orig,
        rowTask: this.rowTask,
        todo: 'changeValuesWorks',
        content: tasksActionsContent
      });
    }
    let inputFailed = false;
    for (const supplieCur of this.suppliesList) {
      if (supplieCur.actionTask === undefined || supplieCur.actionTask === '') {
        inputFailed = true;
      }
      if (supplieCur.quantity === undefined || supplieCur.quantity === null || supplieCur.quantity === 0) {
        inputFailed = true;
      }
    }
    this.statutCur({
      subject: 'partsSupplies',
      failed: inputFailed
    });

  }
  // serchain parsts & supplies
  openSearchModal() {
    this.dataUpstream = {
      equipType: '',
      equipBrand: '',
      equipModel: '',
    };

    this.openModal(this.searchModal);
  }
  // modal action ref
  openActionModal() {
    this.dataUpstream = {
      equipType: '',
      equipBrand: '',
      equipModel: '',
    };
    this.openModal(this.actionModal);
  }
  openModal(template: TemplateRef<any>) {

    // this.modalText = [docCur.docName];
    this.modalRef = this.modalService.show(template, this.configModal);
  }
  closeModal() {

    this.modalRef.hide();
    this.modalRef = undefined;
    // this.modalService.hide();
  }
  statutCur(event: EventStatutCur): void  {
    this.dataOut.emit(event);
  }
}
